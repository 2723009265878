import { useApp } from "hooks";
import { Navigate, Outlet } from "react-router-dom";
import { AuthService } from "services";
import { BuiltRoutePath, SearchParams } from "./router";

/** This router checks and decides whether the current user need to verify his email
 * to access the children routes or not.
 */
export const EmailVerifiedRouter = () => {
  const user = useApp().user;

  if (!user) {
    throw new Error("Non autorisé !", { cause: "missing_user" });
  }

  if (user.has_valid_email) {
    // Clean up storage if user verified email somewhere else
    AuthService.updateShouldVerifyEmailIndicator(user);
  } else if (AuthService.shouldVerifyEmail(user)) {
    // Redirect to email verification page with `next` = current href
    const next = encodeURIComponent(window.location.href);
    const redirectTo = `${BuiltRoutePath.EmailVerificationPage}?${SearchParams.Common.next}=${next}`;

    return <Navigate to={redirectTo} replace />;
  }

  return <Outlet />;
};
