import { Button, Column, Title } from '@yolaw/ui-kit-components';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import useDocumentGenerationTask from '../hooks/useDocumentGenerationTask';
import GenerateAndDownloadButton from './GenerateAndDownloadButton';
import useTask from 'pages/zen/hooks/useTask';
import { useIsMobile } from 'hooks';

const Form = styled.form``;

type InputFormContainerProps = {
  children: React.ReactNode;
};

const MobilePreviewButton = () => {
  const navigate = useNavigate();
  const { isFormReady } = useDocumentGenerationTask();

  const preview = () => {
    navigate('preview');
  };

  return (
    <Button disabled={!isFormReady} onClick={preview}>
      Générer
    </Button>
  );
};

const InputFormContainer = ({ children }: InputFormContainerProps) => {
  const isMobile = useIsMobile();
  const task = useTask();

  const {
    form: { title }
  } = task.details as DocumentTask.Details;

  return (
    <>
      <Title type="H4" color="secondary.main">
        {title}
      </Title>
      <Form>
        <Column>
          {children}

          {isMobile ? <MobilePreviewButton /> : <GenerateAndDownloadButton />}
        </Column>
      </Form>
    </>
  );
};

export default InputFormContainer;
