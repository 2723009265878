import {
  Button,
  Column,
  Text,
  TextField,
  Title,
} from "@yolaw/ui-kit-components";
import { useIsMobile, useSegment } from "hooks";
import { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Link, useSearchParams } from "react-router-dom";
import { AuthService } from "services";
import { BuiltRoutePath, SearchParams } from "services/router";
import styled from "styled-components";
import { EmailUtils } from "utils";
import { isValidEmail } from "utils/email";
import { getNextParam } from "../auth-utils";
import {
  Divider,
  FormFooterActionContainer,
  FormInnerContainer,
} from "../components/misc-components";

const Form = styled(FormInnerContainer).attrs({ as: "form" })``;

type FormInputs = {
  email: string;
  password: string;
};

type LoginPageProps = {
  disableAccountCreation?: boolean;
};

export const LoginPage = ({ disableAccountCreation }: LoginPageProps) => {
  const isMobile = useIsMobile();
  const segment = useSegment();
  const [searchParams] = useSearchParams();
  const emailInParams = searchParams.get(SearchParams.LoginPage.email);
  const defaultEmail =
    emailInParams && isValidEmail(emailInParams) ? emailInParams : "";

  const formMethods = useForm<FormInputs>({
    mode: "onSubmit",
    defaultValues: {
      email: defaultEmail,
      password: "",
    },
  });

  const { clearErrors, control, handleSubmit, formState, setError, watch } =
    formMethods;
  const emailValue = watch("email");
  const passwordValue = watch("password");

  const { errors, isDirty, isSubmitting, isSubmitSuccessful } = formState;

  const onSubmit: SubmitHandler<FormInputs> = async (data: FormInputs) => {
    try {
      const { has_valid_email } = await AuthService.login(
        data.email,
        data.password
      );

      const next = getNextParam();
      const hasVerifiedEmail =
        has_valid_email ??
        // additional attempt if the BE doesn't provide it within login request
        (await AuthService.getUserProfile().then(
          (user) => !!user?.has_valid_email
        ));

      // Check `user.has_valid_email` to decide redirection
      if (hasVerifiedEmail) {
        // true => next
        // Reload with the `next`
        window.location.replace(next);
      } else {
        // false => email verification page with params `source = login`
        // Keep the original source if it's already set
        if (!searchParams.get(SearchParams.Common.source)) {
          // set `source = login` if there is no original source
          searchParams.set(SearchParams.Common.source, "login");
        }

        // Check whether the next URL built by BE is the "/email-verification" or not
        // to remove the email-verification if it's built by BE to prevent duplicated action
        const nextURL = new URL(next);
        if (nextURL.pathname === BuiltRoutePath.EmailVerificationPage) {
          // Delete the next param from the current URL
          searchParams.delete(SearchParams.Common.next);
          // Append the params from the next URL built by BE to the current URL
          nextURL.searchParams.forEach((value, key) =>
            searchParams.append(key, value)
          );
        }

        window.location.replace(
          BuiltRoutePath.EmailVerificationPage + `?${searchParams.toString()}`
        );
      }
    } catch (error: any) {
      setError("root", {
        type: "custom",
        message: error.message,
      });
      // Also set custom field error to highlight the fields
      setError("email", {
        type: "custom",
        message: "",
      });
      setError("password", {
        type: "custom",
        message: "",
      });
    }
  };

  useEffect(() => {
    // Clear root error when any field is changed
    clearErrors("root");
  }, [clearErrors, emailValue, passwordValue]);

  useEffect(() => {
    const source = searchParams.get(SearchParams.Common.source);
    segment.track("login form: displayed", {
      context:
        source === "lafoy-post-payment" ? "funnel paid" : source || "userspace",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Title
          text="Connectez-vous"
          type={isMobile ? "H1" : "H3"}
          color="secondary.main"
        />

        <Column>
          <Controller
            control={control}
            name="email"
            rules={EmailUtils.emailFieldValidationRulesRHF}
            render={({ field: { ref, ...restOfField } }) => (
              <TextField
                type="email"
                label="Votre email"
                id="email"
                placeholder="Entrez votre email"
                showLabel
                disabled={!!defaultEmail || isSubmitting}
                hasError={!!errors.email}
                errorMessage={errors.email?.message}
                {...restOfField}
              />
            )}
          />

          <Controller
            control={control}
            name="password"
            rules={{
              required: "Le champ ci-dessus est requis",
            }}
            render={({ field: { ref, ...restOfField } }) => (
              <TextField
                label="Votre mot de passe"
                type="password"
                id="password"
                placeholder="Entrez votre mot de passe"
                showLabel
                disabled={isSubmitting}
                hasError={!!errors.password}
                errorMessage={errors.password?.message}
                {...restOfField}
              />
            )}
          />
        </Column>

        {errors.root && (
          <Text
            type="small"
            text={errors.root.message}
            color="error.dark"
            fontWeightVariant="bold"
          />
        )}

        <FormFooterActionContainer>
          <Link
            to={
              (emailValue && isValidEmail(emailValue)
                ? BuiltRoutePath.PasswordResetRequestPage
                : BuiltRoutePath.PasswordResetCollectEmailPage) +
              window.location.search
            }
            state={{
              email: emailValue,
            }}
          >
            <Text
              color={isSubmitting ? "neutral.light" : undefined}
              fontWeightVariant="bold"
            >
              Mot de passe oublié ?
            </Text>
          </Link>

          <Button
            type="submit"
            variant="primary"
            disabled={!isDirty || isSubmitting || isSubmitSuccessful}
            isLoading={isSubmitting}
          >
            Me connecter
          </Button>
        </FormFooterActionContainer>
      </Form>

      {/* Disallow creating a new user account if it comes from Lafoy post-payment.
        It's supposed to have and we should continue with that account. */}
      {!disableAccountCreation && (
        <>
          <Divider />
          <Text>
            Pas encore de compte ?{" "}
            <Link to={BuiltRoutePath.SignUpPage}>Créer mon compte</Link>
          </Text>
        </>
      )}
    </>
  );
};
