import { Button, Text } from "@yolaw/ui-kit-components";
import { CancelButton, ModalForm } from "components/FormStyles";
import Modal from "components/modal/Modal";
import { DecisionsService } from "services";
import styled from "styled-components";
import useDecisionsContext from "../hooks/useDecisionsContext";

const ContentContainer = styled.div`
  text-align: center;
`;

type DecisionDeleteModalProps = {
  isOpen: boolean;
};

const DecisionDeleteModal: React.FC<DecisionDeleteModalProps> = ({
  isOpen,
}) => {
  const { openingModal, closeModal, deleteDecision } = useDecisionsContext();
  const decision = openingModal?.context?.decision;

  const onDeleteButtonClick = () => {
    if (decision) {
      // remove from the local context
      deleteDecision(decision.id);
      // API request
      DecisionsService.deleteDecision(decision.id);
    }
    closeModal();
  };

  const FooterContent = (
    <>
      <CancelButton
        formMethod="dialog"
        onClick={closeModal}
        icon="ChevronDownFlat"
      >
        Annuler
      </CancelButton>
      <Button type="submit" onClick={onDeleteButtonClick}>
        Supprimer
      </Button>
    </>
  );

  return (
    <Modal
      onClose={closeModal}
      isOpen={isOpen}
      title="Supprimer une décision"
      FooterContent={FooterContent}
    >
      <ModalForm id="DecisionDeleteForm">
        <ContentContainer>
          <Text>Êtes-vous sûr de vouloir supprimer votre décision ?</Text>
        </ContentContainer>
      </ModalForm>
    </Modal>
  );
};

export default DecisionDeleteModal;
