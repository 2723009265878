import { PATH_TEMPLATES } from "pages/zen/routes";
import React from "react";
import { matchPath, Navigate, Route, Routes, useMatch } from "react-router-dom";
import useQuestionnaire from "../hooks/useQuestionnaire";

type QuestionnaireProps = {
  children: JSX.Element;
};

type PageLevel =
  | "Questionnaire"
  | "QuestionnairePage"
  | "QuestionnairePageWithoutForm";

export const getNextChildLevel = (
  currentLevel: PageLevel,
  currentPathname: string
) => {
  switch (currentLevel) {
    case "Questionnaire":
    case "QuestionnairePageWithoutForm":
      return {
        slugId: "pageSlug",
        pathPattern: `${currentPathname}/:pageSlug`,
      };
    default:
      throw Error(
        "Incorrect PageLevel configuration! Please check either page-level component name or Questionnaire pages hierarchy!"
      );
  }
};

export const shouldRedirectToNextChild = (
  currentLevel: PageLevel,
  currentPathname: string,
  children: JSX.Element
): string | null => {
  const nextChildLevel = getNextChildLevel(currentLevel, currentPathname);
  if (!nextChildLevel) return null;

  const nextLevelMatch = matchPath(nextChildLevel.pathPattern, currentPathname);

  if (!nextLevelMatch?.params[nextChildLevel.slugId]) {
    const firstChild: any = React.Children.toArray(children)[0];

    if (firstChild !== undefined) {
      return firstChild.props.slug;
    }
  }

  return null;
};

const Questionnaire = ({ children }: QuestionnaireProps) => {
  const { resumePageSlug } = useQuestionnaire();

  const matchOnboarding = useMatch(PATH_TEMPLATES.ONBOARDING);
  const matchTaskView = useMatch(PATH_TEMPLATES.TASK_VIEW);
  const matchBase = matchOnboarding || matchTaskView;
  const redirectTo =
    resumePageSlug ||
    shouldRedirectToNextChild(
      "Questionnaire",
      window.location.pathname,
      children
    );

  return (
    <>
      <Routes>
        {React.Children.map(children, (QuestionnairePageComponent) => {
          return (
            <Route
              key={QuestionnairePageComponent.props.slug}
              path={`${QuestionnairePageComponent.props.slug}/*`}
              element={QuestionnairePageComponent}
            />
          );
        })}
      </Routes>
      {matchBase && redirectTo && (
        <Navigate to={`${redirectTo}`} replace={true} />
      )}
    </>
  );
};

export default Questionnaire;
