import { Button, Container, Text, Title } from "@yolaw/ui-kit-components";
import useZenRoutes from "pages/zen/hooks/useZenRoutes";
import styled, { css } from "styled-components";

const Iframe = styled.iframe`
  width: 560px;
  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.m}px) {
      width: 100%;
    }
  `};
`;

const Succeed = () => {
  const { goToDashboard } = useZenRoutes();

  const handleCtaClick = () => {
    goToDashboard({ navigateOptions: { replace: true } });
  };

  return (
    <>
      <Title type="H2" color="secondary.main">
        🎉 Super, vous avez votre attestation pour le mois de mars&nbsp;! Et
        maintenant comment faire&nbsp;?
      </Title>
      <Container size="s">
        <Text type="BODY" color="neutral.dark">
          💡 Suivez le tutoriel pour envoyer votre attestation à Pole Emploi
        </Text>
      </Container>
      <Iframe
        height="315"
        src="https://www.youtube.com/embed/380wUg2p6FY"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
      <Container size="xs">
        <Text type="BODY" color="neutral.dark" fontWeightVariant="bold">
          ⚠️ Vous devez joindre vos statuts à votre attestation de non
          rémunération.
        </Text>
      </Container>

      <Container size="xs">
        <Button onClick={handleCtaClick}>C'est fait !</Button>
      </Container>
    </>
  );
};

export default Succeed;
