import {
  Button,
  Column,
  Hint,
  HintType,
  List,
  Title,
} from "@yolaw/ui-kit-components";
import RegisterTaskCompleteImage from "pages/zen/assets/svgs/task-complete-register.svg";
import TaskCompleteImage from "pages/zen/assets/svgs/task-complete.svg";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import useTask from "pages/zen/hooks/useTask";
import useZenProject from "pages/zen/hooks/useZenProject";
import useZenRoutes from "pages/zen/hooks/useZenRoutes";
import styled, { css } from "styled-components";

const MainContainer = styled(Column)`
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  ${({ theme }) => css`
    row-gap: ${theme.spacing.l}px;
    padding: ${theme.spacing.m}px;

    @media (max-width: ${theme.breakpoints.m}px) {
      > img {
        height: 224px;
      }
    }
  `}
`;

const StyledHint = styled(Hint)`
  text-align: left;
`;

const HintCustomDescription = styled.div`
  margin-top: ${(props) => props.theme.spacing.xxxs}px;
`;

const StyledList = styled(List)`
  margin: 0;
  padding: 0;
  color: ${(props) => props.theme.colors.neutral.darker};
  font-weight: 400;
`;

const RegisterTaskComplete = () => {
  const project = useZenProject();
  const hqAddress = _get(project.onboardingQuestionnaire?.answers, ["hq"], {});

  const hintListItems = [
    "Quand votre société sera immatriculée",
    "Quand votre abonnement Zen sera payé",
  ];

  if (!_isEmpty(hqAddress)) {
    hintListItems.push(
      `Adresse de livraison : ${_get(hqAddress, ["address"])}, ${_get(
        hqAddress,
        ["zip"]
      )} ${_get(hqAddress, ["city"])}`
    );
  }

  return (
    <>
      <img src={RegisterTaskCompleteImage} alt="task-complete" />
      <Column style={{ rowGap: 24 }}>
        <Title type="H2">La commande est prise en compte ! 🎉</Title>
      </Column>
      <StyledHint type={HintType.Information}>
        Vos registres seront expédiés plus tard :
        <HintCustomDescription>
          <StyledList items={hintListItems} />
        </HintCustomDescription>
      </StyledHint>
    </>
  );
};

const TaskCompleteConfirmation = () => {
  const task = useTask();
  const { goToDashboard } = useZenRoutes();

  const handleClick = () => goToDashboard({ searchParams: "?utm_source=task" });

  return (
    <MainContainer>
      {task.type.slug === "register" ? (
        <RegisterTaskComplete />
      ) : (
        <>
          <img src={TaskCompleteImage} alt="task-complete" />
          <Title type="H2">Vous avez terminé cette tâche !</Title>
        </>
      )}
      <Button onClick={handleClick}>Retour à mon espace</Button>
    </MainContainer>
  );
};

export default TaskCompleteConfirmation;
