import { Button, Column, Hint, HintType, Text } from "@yolaw/ui-kit-components";
import { useEffect } from "react";
import styled, { css } from "styled-components";

import Modal from "components/modal/Modal";
import { ModalName } from "contexts/app";
import { useApp, useLegalEntity, useSegment } from "hooks";
import { PageContentHeader } from "pages/components";
import { PageContainer } from "pages/components/PageStyles";
import useZenProject from "pages/zen/hooks/useZenProject";
import MailboxImage from "./assets/mailbox.svg";

const HintInner = styled(Column)`
  && {
    row-gap: 0px;
  }
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;

const ModalContentContainer = styled(Column)`
  ${({ theme }) => css`
    && {
      row-gap: ${theme.spacing.s}px;
    }
  `}
  text-align: center;
  align-items: center;
  max-width: 440px;
  margin: auto;
`;

type ModalProps = {
  isOpen: boolean;
};
const ConfirmationModal = ({ isOpen }: ModalProps) => {
  const app = useApp();
  const project = useZenProject();
  const { hq = {} } = project.onboardingQuestionnaire?.answers || {};

  return (
    <Modal
      isOpen={isOpen}
      onClose={app.closeModal}
      title="Votre commande est prise en compte !"
    >
      <ModalContentContainer>
        <img src={MailboxImage} alt="check-mailbox" />
        <Text>
          Vos registres de mouvements de titre vous seront envoyés à l’adresse :{" "}
          {hq.address || "None"}, {hq.zip || "None"} {hq.city || "None"}
        </Text>
        <Button onClick={app.closeModal}>Retour à mon espace</Button>
      </ModalContentContainer>
    </Modal>
  );
};

const OrderTitleRecordsPage = () => {
  const app = useApp();
  const currentLegalEntity = useLegalEntity();
  const project = useZenProject();
  const segment = useSegment();
  const { hq = {} } = project.onboardingQuestionnaire?.answers || {};

  const handleOrderTitleRecords = () => {
    if (!currentLegalEntity.zenSub) {
      // This action need an active Zen sub
      app.openModal({
        name: ModalName.ZenSubscriptionPayment,
        openedBy: {
          context: "order title records page",
          from: "order title records button",
        },
      });
      return;
    }

    segment.track("mandatory documents: ordered", {
      email: app.user?.email,
      company_name: project.info.company_name,
      address: hq.address,
      zipcode: hq.zip,
      city: hq.city,
      funnel_id: project.info.funnel_id,
    });

    app.openModal({
      name: ModalName.RecordsOrderTitleRecordsSuccess,
      openedBy: {
        context: "order title records page",
        from: "order title records button",
      },
    });
  };

  useEffect(() => {
    segment.track("e-records titles: displayed", {
      has_siren: project.hasCompanySiren,
      legalentity_id: project.info.legalentity_id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageContainer>
        <PageContentHeader
          title="Registre des mouvements de titre"
          InformationComponent={
            <Hint type={HintType.Warning}>
              <HintInner>
                <Text fontWeightVariant="bold">
                  Les registres des mouvements de titres ne sont pas encore
                  disponibles en format dématérialisé. Si besoin, vous pouvez
                  commander une version papier dès maintenant.
                </Text>
              </HintInner>
            </Hint>
          }
        />

        <ButtonWrapper>
          <Button size="medium" onClick={handleOrderTitleRecords}>
            Commander le registre en version papier
          </Button>
        </ButtonWrapper>
      </PageContainer>
      <ConfirmationModal
        isOpen={
          app.openingModal?.name === ModalName.RecordsOrderTitleRecordsSuccess
        }
      />
    </>
  );
};

export default OrderTitleRecordsPage;
