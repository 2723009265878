import { useCallback, useMemo } from "react";

import {
  LegacySubscriptionService,
  ServicesService,
  SubscriptionsService,
} from "services";
import { SubscriptionFilters } from "services/subscriptions";
import { LegacySubscriptionSlug } from "types/legacy-subscriptions";
import {
  LegalEntity,
  LegalStructure,
  LegalStructureType,
} from "types/legal-entities";
import {
  SubscriptionFamilySlug,
  SubscriptionStatus,
} from "types/subscriptions";
import { useApp } from "./useApp";

const LegalStructureTypeMap: Record<LegalStructureType, LegalStructure[]> = {
  [LegalStructureType.Association]: [LegalStructure.Association],
  [LegalStructureType.Commercial]: [
    LegalStructure.SAS,
    LegalStructure.SASU,
    LegalStructure.EURL,
    LegalStructure.SARL,
  ],
  [LegalStructureType.RealEstate]: [LegalStructure.SCI],
  [LegalStructureType.MicroOrAutoEnterprise]: [
    LegalStructure.IndividualEnterprise,
    LegalStructure.AutoEnterprise,
  ],
};

const EMPTY_LEGAL_ENTITY: LegalEntity = {
  funnel_id: null,
  id: 0,
  legal_structure: "" as LegalStructure,
  name: "",
  workflow_id: null,
};

/** Enhance the Legal Entity object.
 * The App's current LegalEntity is used if the `legalEntity` params is not provided.
 */
export const useLegalEntity = (legalEntityArgs?: LegalEntity | null) => {
  const app = useApp();

  const currentLegalEntity = app.legalEntities?.find(
    (le) => le.id === app.currentLegalEntityId
  );
  const legalEntityObj =
    legalEntityArgs || currentLegalEntity || EMPTY_LEGAL_ENTITY;

  const { id, legal_structure, has_access_to_zen, service_id_for_zen } =
    legalEntityObj;

  /** Check whether the legal structure is the same as the given legal structure */
  const isLegalStructure = (legalStructure: LegalStructure) =>
    legal_structure.toUpperCase() === legalStructure;

  /** Check whether the legal structure is of a type/group */
  const isLegalStructureType = (type: LegalStructureType) =>
    LegalStructureTypeMap[type].includes(
      legal_structure.toUpperCase() as LegalStructure
    );

  const isOneOfLegalStructureTypes = (types: LegalStructureType[]) =>
    types.some((type) => isLegalStructureType(type));

  const leSubscriptions = (app.subscriptions || [])?.filter(
    (sub) => sub.legal_entity_id === id
  );

  const leLegacySubscriptions = (app.legacySubscriptions || []).filter(
    (sub) => sub.legal_entity_id === id
  );

  /** Find ACTIVE subscription */
  const findSubscription = useCallback(
    (subFamilySlug: SubscriptionFamilySlug, filters?: SubscriptionFilters) =>
      SubscriptionsService.findSubscription(
        leSubscriptions,
        subFamilySlug,
        filters
      ),
    [leSubscriptions]
  );

  /** Find accessible LS Compte Pro subscription with statuses decided by product team */
  const lsCompteProAccessibleSubscription = useMemo(() => {
    const lscpSub = findSubscription(
      SubscriptionFamilySlug.LSBusinessCapitalDeposit,
      {
        statuses: [
          SubscriptionStatus.Draft,
          SubscriptionStatus.Incomplete,
          SubscriptionStatus.Trialing,
          SubscriptionStatus.Active,
          SubscriptionStatus.PastDue,
        ],
      }
    );

    if (!lscpSub) return undefined;

    /** Build and return the SSO URL if it's qualified. Otherwise, return `null`. */
    const buildSSORedirectionURL = (lsBusinessPathname?: string) => {
      // Qualification: LE has Siren & LSCP Sub has a Redirection URL
      if (!!legalEntityObj.siren && !!lscpSub.redirection_url) {
        // Build the Destination URL
        const destinationURL =
          // It's expected to be https://business.legalstart.fr/companies/{company_id}
          `${lscpSub.redirection_url}/${lsBusinessPathname || ""}`;

        // Build the SSO params with Destination URL
        const encodedDestinationURL = encodeURIComponent(destinationURL);
        // Build the complete SSO URL
        const ssoURL = `${process.env.REACT_APP_LS_COMPTE_PRO_SSO_URL}?RelayState=${encodedDestinationURL}`;

        // Return SSO URL
        return ssoURL;
      }

      // Not qualified
      return null;
    };

    return {
      ...lscpSub,
      buildSSORedirectionURL,
    };
  }, [findSubscription, legalEntityObj.siren]);

  /** Find ACTIVE legacy subscription (NOTE: with assumption that backend only provides active subscriptions) */
  const findLegacySubscription = (
    subSlugOrPrefix: LegacySubscriptionSlug | string
  ) =>
    LegacySubscriptionService.findLegacySubscription(
      leLegacySubscriptions,
      subSlugOrPrefix
    );

  const findCSActiveSubscription = () =>
    findLegacySubscription("comptastart-") ||
    findLegacySubscription(LegacySubscriptionSlug.WebComptastart);

  const findSOJActiveSubscription = () =>
    findLegacySubscription(LegacySubscriptionSlug.SOJ);

  /** LE has subscribed a legacy subscription and the subscription is still active */
  const hasSubscribedLegacySubscription = (
    subSlugOrPrefix: LegacySubscriptionSlug | string
  ): boolean => !!findLegacySubscription(subSlugOrPrefix);

  const zenSub = findLegacySubscription(LegacySubscriptionSlug.Zen);

  const getZenAccessURL = useCallback(async () => {
    // Try getting the redirection URL from the subscription
    if (zenSub?.redirection_url) {
      return zenSub.redirection_url;
    }

    // In case there is no subscription, try getting the URL from BE
    else if (has_access_to_zen && service_id_for_zen) {
      const accessURL = await ServicesService.getZenAccessURL(
        service_id_for_zen
      );
      // Check again the availability of the subscription redirection URL
      // because it could be set during this request is being performed
      if (zenSub?.redirection_url) {
        return zenSub.redirection_url;
      } else {
        return accessURL;
      }
    }

    // Remove the previous access URL if any
    else {
      return null;
    }
  }, [has_access_to_zen, service_id_for_zen, zenSub?.redirection_url]);

  return {
    ...legalEntityObj,
    getZenAccessURL,

    // Company type (aka legal structure) checks
    isLegalStructure,
    isLegalStructureType,
    isAssociationType: isLegalStructureType(LegalStructureType.Association),
    isCommercialType: isLegalStructureType(LegalStructureType.Commercial),
    isRealEstateType: isLegalStructureType(LegalStructureType.RealEstate),
    isMicroOrAutoEnterpriseType: isLegalStructureType(
      LegalStructureType.MicroOrAutoEnterprise
    ),
    isOneOfLegalStructureTypes,

    // Subscription finder methods
    findSubscription,
    // Subscriptions
    lsCompteProAccessibleSubscription,

    // Legacy subscription finder methods
    findLegacySubscription,
    findCSActiveSubscription,
    findSOJActiveSubscription,
    // Subscription checker methods
    hasSubscribedLegacySubscription,
    hasSubscribedCS: !!findCSActiveSubscription(),
    hasSubscribedSOJ: !!findSOJActiveSubscription(),
    hasSubscribedSM:
      hasSubscribedLegacySubscription(
        LegacySubscriptionSlug.SurveillanceMarque
      ) ||
      hasSubscribedLegacySubscription(
        LegacySubscriptionSlug.WebSurveillanceMarque
      ),
    // Legacy subscriptions
    zenSub,
  };
};
