export enum SubscriptionFamilySlug {
  LSBusinessCapitalDeposit = "ls-business-capital-deposit",
}

interface SubscriptionFamily {
  id: number;
  name: string;
  slug: SubscriptionFamilySlug;
}

interface SubscriptionProduct {
  id: number;
  name: string;
  slug: string;
}

interface SubscriptionPlan {
  id: number;
  is_active: boolean;
  period_interval: number;
  period_unit: string;
  trialing_unit: string | null;
  trialing_amount: number | null;
}

export enum SubscriptionStatus {
  Active = "active",
  Canceled = "canceled",
  Draft = "draft",
  Incomplete = "incomplete",
  IncompleteExpired = "incomplete_expired",
  PastDue = "past_due",
  PausedCollection = "paused_collection",
  Trialing = "trialing",
}

export interface Subscription {
  cancel_at_period_end: boolean;
  canceled_at: string | null;
  cancellation_planned_date: string | null;
  created_at: string;
  current_period_start_date: string | null;
  current_period_end_date: string | null;
  family: SubscriptionFamily;
  id: number;
  legal_entity_id: number;
  next_billing_date: string | null;
  next_billing_amount: number | null;
  next_tier_id: number | null;
  planned_action: "CANCEL" | "UPSELL" | "DOWNSELL" | null;
  plan: SubscriptionPlan; redirection_url: string | null;
  product: SubscriptionProduct;
  requested_cancellation_at: string | null;
  status: SubscriptionStatus;
  tier_id: number;
  trial_start_date: string | null;
  trial_end_date: string | null;
}
