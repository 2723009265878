import { Button, Column, displayToast, Text } from "@yolaw/ui-kit-components";
import { useApp, useSegment } from "hooks";
import useCountDown from "hooks/useCountDown";
import { useEffect } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { AuthService, UserService } from "services";
import { BuiltRoutePath, SearchParams } from "services/router";
import styled, { css } from "styled-components";
import { ERROR_MESSAGES } from "utils/constants";
import mailSvgSrc from "../assets/mail.svg";
import { getNextParam } from "../auth-utils";
import { Divider } from "../components/misc-components";

const Container = styled(Column)`
  ${({ theme }) => css`
    align-items: center;
    text-align: center;
    color: ${theme.colors.neutral.darker};

    @container (max-width: ${theme.breakpoints.m}px) {
      && {
        row-gap: ${theme.spacing.m}px;
      }
    }

    @container (min-width: ${theme.breakpoints.m + 1}px) {
      && {
        row-gap: ${theme.spacing.s}px;
      }
    }
  `}
`;

const ActionsContainer = styled(Column)`
  ${({ theme }) => css`
    @container (max-width: ${theme.breakpoints.m}px) {
      && {
      }
    }

    @container (min-width: ${theme.breakpoints.m + 1}px) {
      && {
        align-items: inherit;
      }
    }
  `}
`;

const RESEND_COUNTDOWN_SECONDS = 15;

/** Email verification page.
 * @locationState `disableInitialRequest` decides whether the an initial email verification request should be sent automatically or not.
 * @locationState `disableCounter` decides whether the initial counter should be triggered or not.
 */
const EmailVerificationPage = () => {
  const app = useApp();
  const segment = useSegment();
  const { state: locationState } = useLocation();
  const [searchParams] = useSearchParams();
  // if it's changed and set from the EmailModificationPage
  const newEmail = searchParams.get(
    SearchParams.EmailVerificationPage.newEmail
  );

  const source = searchParams.get(SearchParams.Common.source) as
    | typeof SearchParams.Common.source
    | null;

  const counter = useCountDown();

  const sendEmail = async ({ shouldDisplayNoti = true }) => {
    try {
      const nextParam = getNextParam();
      if (newEmail) {
        // New email is set => use the UPDATE initial email request
        await UserService.updateInitialEmail({
          next: nextParam,
          email: newEmail,
        });
      } else {
        // Current User email => use the initial email request
        await UserService.initialEmail({
          next: nextParam,
        });
      }

      counter.start(RESEND_COUNTDOWN_SECONDS);

      if (shouldDisplayNoti) {
        displayToast({
          content: "Un nouvel email avec un lien de vérification vient de vous être renvoyé.",
          type: "info",
        });
      }
    } catch (error: any) {
      displayToast({
        content: error.message || ERROR_MESSAGES.DEFAULT,
        type: "error",
      });
    }
  };

  const resendEmail = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    sendEmail({ shouldDisplayNoti: true });
  };

  useEffect(() => {
    segment.track("user email verification link sent page: displayed", {
      context: source === "lafoy-post-payment" ? "funnel" : source,
    });

    if (locationState?.disableInitialRequest) {
      // The request is supposed to send by an action before reaching this page.
      // => Don't send the request
      if (!locationState?.disableCounter) {
        // Start the counter unless it's disabled.
        counter.start(RESEND_COUNTDOWN_SECONDS);
      }
    } else {
      // Send the request but don't display the notification.
      sendEmail({ shouldDisplayNoti: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (app.user) {
      AuthService.updateShouldVerifyEmailIndicator(app.user);
    }
  }, [app.user]);

  if (!app.user) return null;

  const isPostLoginOrSignUp =
    !!source && ["login", "signup"].includes(source);

  return (
    <Container>
      <img src={mailSvgSrc} width={124} alt="mail" />
      <Text fontWeightVariant="bold">
        {isPostLoginOrSignUp
          ? "La vérification d'email est désormais obligatoire pour renforcer la sécurité, un email a été envoyé à "
          : "Un email avec un lien de vérification a été envoyé à "}
        {newEmail || app.user.email}
      </Text>

      <Divider />

      <ActionsContainer>
        <Text>
          N'oubliez pas de vérifier vos spams. Si vous ne recevez pas l'email
          dans quelques minutes, cliquer sur :
        </Text>
        <Button
          variant="secondary"
          disabled={counter.isCounting}
          onClick={resendEmail}
        >
          Renvoyer l'email
        </Button>
        {counter.isCounting && (
          <Text>
            Vous pourrez renvoyer l’email dans {counter.remainingSeconds}{" "}
            secondes.
          </Text>
        )}
        <Text>ou</Text>
        <Link
          to={BuiltRoutePath.EmailModificationPage + window.location.search}
        >
          Modifier l'email
        </Link>
      </ActionsContainer>

      {!isPostLoginOrSignUp && (
        <>
          <Divider />

          <Text>
            Si vous créez cette démarche pour le compte d'un tiers, merci de
            renseigner votre adresse email et non celle du tiers.
          </Text>
        </>
      )}
    </Container>
  );
};

export default EmailVerificationPage;
