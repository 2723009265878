import { APP_BANNER_ELM_ID } from "./AppBanner";

/** Store the dismissal state of the App Banner.
 * Any value is consider as it's dismissed.
 */
const LS_APP_BANNER_DISMISSED_KEY = "ls_app_banner_dismissed";

const dismiss = () => {
  // Remove the banner content
  const appBannerElm = document.getElementById(APP_BANNER_ELM_ID);
  appBannerElm?.replaceChildren();

  // Store the dismissal state in localstorage
  localStorage.setItem(LS_APP_BANNER_DISMISSED_KEY, "true");
};

const hasDismissed = () =>
  !!localStorage.getItem(LS_APP_BANNER_DISMISSED_KEY);

/** Reset the banner dismissal state */
const reset = () => {
  localStorage.removeItem(LS_APP_BANNER_DISMISSED_KEY);
};

const AppBannerManager = {
  dismiss,
  hasDismissed,
  reset,
};

export default AppBannerManager;
