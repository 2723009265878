import { css, styled } from "styled-components";

const LegalEntityBaseButton = styled.button`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    column-gap: ${theme.spacing.xxxs}px;
    width: 100%;
    padding: ${theme.spacing.xxxxs}px;
    border: none;
    background-color: ${theme.colors.common.white};
    color: ${theme.colors.secondary.main};
    font-size: 20px;
    font-weight: 800;
    line-height: 1.5;
    white-space: nowrap;
    cursor: pointer;

    > svg {
      flex-shrink: 0;
    }

    > .le_selector-label_container {
      display: flex;
      align-items: center;
      column-gap: ${theme.spacing.xxxs}px;
      overflow: hidden;
      border-radius: ${theme.borderRadius.xs}px;
      > .le_selector-le_name_label {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      > .le_selector-le_tag {
        flex-shrink: 0;
        max-height: 24px;
      }
    }
  `}
`;

export default LegalEntityBaseButton;
