import LSUser from "types/user";
import coreApiInstance from "../coreApi";

export const getAdminProfile = (token: string) =>
  coreApiInstance.get<LSUser.Info | undefined>("/users/me/", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      disableAuthHeaderOverrideInInterceptor: true,
    },
  });

interface ViewAsTokenSuccessResponse {
  access: string;
  impersonated_user_id: number;
  impersonated_user_email: string;
}

interface ViewAsTokenErrorResponse {
  detail?: string;
  message?: string;
}

type ViewAsTokenResponse = ViewAsTokenSuccessResponse &
  ViewAsTokenErrorResponse;

export const getViewAsToken = (email: string) =>
  coreApiInstance.get<ViewAsTokenResponse>("/view-as-tokens/", {
    params: { email },
    validateStatus: (status: number) => status < 500,
    // headers: getAdminRequestHeaders(), // doesn't needed as the admin only use this when he is the current normal user
  });
