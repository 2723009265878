import { Button } from "@yolaw/ui-kit-components";
import { ModalName } from "contexts/app";
import { useApp, useIsMobile, useSegment } from "hooks";
import useTask from "pages/zen/hooks/useTask";
import useZenProject from "pages/zen/hooks/useZenProject";
import { useContext } from "react";
import { DocumentGenerationContext } from "../context";
import useDocumentGenerationTask from "../hooks/useDocumentGenerationTask";

const FreemiumCTA = () => {
  const app = useApp();
  const project = useZenProject();
  const segment = useSegment();
  const task = useTask();

  const buttonText = "Obtenir mon document";

  const handleCTAClick = () => {
    segment.track("zen task cta: clicked", {
      has_siren: project.hasCompanySiren,
      task_id: task.id,
      task_category: task.type.category,
      task_class: task.taskClass,
      task_type: task.type.kind,
      task_name: task.type.title,
      button_variant: "primary",
      button_label: buttonText,
    });

    app.openModal({
      name: ModalName.ZenSubscriptionPayment,
      openedBy: {
        context: "doc gen",
        from: "FreemiumCTA",
      },
    });
  };

  return <Button onClick={handleCTAClick}>{buttonText}</Button>;
};

const GenerateAndDownloadButton = () => {
  const isMobile = useIsMobile();
  const project = useZenProject();
  const task = useTask();
  const segment = useSegment();
  const documentContext = useContext(DocumentGenerationContext);

  const { isGeneratingDocument } = documentContext.state;
  const { isFormReady, generateAndDownloadDocument } =
    useDocumentGenerationTask();

  const { buttons } = task.details as DocumentTask.Details;

  const mainCtaText =
    buttons?.main_cta?.text ||
    (isMobile ? "Télécharger mon document" : "Générer et télécharger");

  const handleButtonClick = () => {
    segment.track("zen task cta: clicked", {
      has_siren: project.hasCompanySiren,
      task_id: task.id,
      task_category: task.type.category,
      task_class: task.taskClass,
      task_type: task.type.kind,
      task_name: task.type.title,
      button_variant: "primary",
      button_label: mainCtaText,
    });

    generateAndDownloadDocument();
  };

  return project.isZenSubscriptionNeeded ? (
    <FreemiumCTA />
  ) : (
    <Button
      disabled={!isFormReady || isGeneratingDocument}
      isLoading={isGeneratingDocument}
      onClick={handleButtonClick}
    >
      {mainCtaText}
    </Button>
  );
};

export default GenerateAndDownloadButton;
