import { Button, Text } from "@yolaw/ui-kit-components";
import { DownloadFlat } from "@yolaw/ui-kit-icons";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";

import { ModalName } from "contexts/app";
import { useApp, useLegalEntity, useSegment } from "hooks";
import { AddDocumentPaths } from "./add-document";
import {
  DocumentTemplate,
  getDocumentTemplatesByLegalStructure,
} from "./document-templates";

const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing.m}px;

    .separator {
      background-color: ${theme.colors.secondary.dark};
      position: relative;

      .label {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
      }
    }

    .templates-list-container {
      display: flex;
      flex-direction: column;
      text-align: left;
      row-gap: ${theme.spacing.xs}px;

      .list {
        list-style: none;
        display: flex;
        flex-direction: column;
        row-gap: ${theme.spacing.xxxs}px;

        .template-download-button {
          background: none;
          border: none;
          cursor: pointer;

          .label {
            display: flex;
            align-items: baseline;
            column-gap: ${theme.spacing.xxxs}px;
            text-align: left;
            color: ${theme.colors.neutral.darker};
            > svg {
              flex-shrink: 0;
            }
          }
        }
      }
    }

    /* MOBILE */
    @container (max-width: ${theme.breakpoints.l}px) {
      flex-wrap: wrap;
      justify-content: center;

      > * {
        width: 100%;
      }

      .separator {
        width: 80%;
        height: 1px;
        .label {
          padding: 0 ${theme.spacing.xxxs}px;
        }
      }

      .templates-list-container {
        width: 80%;
      }
    }

    /* DESKTOP */
    @container (min-width: ${theme.breakpoints.l}px) {
      flex-wrap: wrap;

      .separator {
        width: 1px;
        height: 100%;

        .label {
          padding: ${theme.spacing.xxxs}px 0;
        }
      }
    }
  `}
`;

const DocumentTemplateDownloadButton = ({
  documentTemplate,
}: {
  documentTemplate: DocumentTemplate;
}) => {
  const segment = useSegment();
  const currentLegalEntity = useLegalEntity();

  const { link, label, type } = documentTemplate;

  const openDocumentTemplateLink = () => {
    segment.track("e-records decision template: downloaded", {
      context: "e-records decisions",
      "document type": type,
      "social form": currentLegalEntity.legal_structure,
    });

    window.open(link, "_blank");
  };

  return (
    <button
      className="template-download-button"
      onClick={openDocumentTemplateLink}
    >
      <div className="label">
        <DownloadFlat />
        {label}
      </div>
    </button>
  );
};

const UploadDocumentOrDownloadTemplate = () => {
  const app = useApp();
  const currentLegalEntity = useLegalEntity();
  const navigate = useNavigate();

  const documentTemplates = getDocumentTemplatesByLegalStructure(
    currentLegalEntity.legal_structure
  );

  const openAddDocumentPage = () => {
    if (!currentLegalEntity.zenSub) {
      // This action need an active Zen sub
      app.openModal({
        name: ModalName.ZenSubscriptionPayment,
        openedBy: {
          context: "e-records decision view",
          from: "decision add document button",
        },
      });
      return;
    }

    navigate(AddDocumentPaths.index);
  };

  return (
    <Container>
      <Button icon="PlusCircleFlatV3" onClick={openAddDocumentPage}>
        Ajouter un document
      </Button>
      {documentTemplates.length ? (
        <>
          <div className="separator">
            <span className="label">ou</span>
          </div>
          <div className="templates-list-container">
            <Text color="neutral.darker" fontWeightVariant="bold">
              Télécharger un modèle
            </Text>
            <ul className="list">
              {documentTemplates.map((documentTemplate) => (
                <li key={documentTemplate.type}>
                  <DocumentTemplateDownloadButton
                    key={documentTemplate.type}
                    documentTemplate={documentTemplate}
                  />
                </li>
              ))}
            </ul>
          </div>
        </>
      ) : null}
    </Container>
  );
};

export default UploadDocumentOrDownloadTemplate;
