import { toast } from "@yolaw/ui-kit-components";
import { InformationBubbleFlat } from "@yolaw/ui-kit-icons";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import styled, { css } from "styled-components";

import { useSegment } from "hooks";
import { PageContainer } from "pages/components/PageStyles";
import KbisPreviewer from "../components/KbisPreviewer";
import MessageBox from "../components/MessageBox";
import useZenProject from "../hooks/useZenProject";
import { downloadKbisFile, hasDownloadedKbis } from "../utils/project";

const CoverContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;

  transition: opacity 0.3s ease-out;

  ${({ theme }) => css`
    @media (min-width: ${theme.breakpoints.m}px) {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @media (max-width: ${theme.breakpoints.m}px) {
      left: 0;
      top: 0;
    }
  `};

  .kbis-previewer {
    height: 100%;
  }
`;

type KbisPreviewerProps = {
  onDownloadCompleted: () => void;
};

const KbisPreviewerCover = ({ onDownloadCompleted }: KbisPreviewerProps) => {
  const project = useZenProject();
  const shouldDisplayKbisPreviewCover =
    project.info.has_kbis &&
    !!project.info.kbis_url &&
    !hasDownloadedKbis(project.info.id);

  if (!shouldDisplayKbisPreviewCover) return null;

  const hidePreviewerCover = () => {
    const coverElement = document.getElementById("cover-container");
    if (coverElement) {
      coverElement.style.setProperty("opacity", "0");
      setTimeout(() => {
        coverElement.style.setProperty("display", "none");
      }, 300);
    }
    onDownloadCompleted();
  };

  return (
    <CoverContainer id="cover-container">
      <KbisPreviewer onDownloadCompleted={hidePreviewerCover} />
    </CoverContainer>
  );
};

const DashboardContainer = styled(PageContainer)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing.l}px;
  `}
`;

const DashboardLayout = () => {
  const project = useZenProject();
  const segment = useSegment();

  useEffect(() => {
    if (project.info.id) {
      // Refresh the task list when any dashboard is mounted
      project.getTasks();
    }

    return () => {
      // Dismiss any active toast when moving to other route
      toast.dismiss();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [reDownloadKbisMessageBoxVisible, setReDownloadKbisMessageBoxVisible] =
    useState(
      project.info.has_kbis &&
        !!project.info.kbis_url &&
        hasDownloadedKbis(project.info.id)
    );

  const reDownloadKbis = () => {
    downloadKbisFile(project.info);
    segment.track("kbis: downloaded", {
      context: project.currentDashboard,
      from: "zen-bottom-banner",
    });
  };

  const onDownloadKbisFromPreviewerComplete = () => {
    setReDownloadKbisMessageBoxVisible(true);
  };

  return (
    <>
      <DashboardContainer className="zen-dashboard-container">
        <Outlet />

        {reDownloadKbisMessageBoxVisible && (
          <MessageBox
            message={
              "Vous souhaitez télécharger votre Kbis à nouveau ? C'est par ici !"
            }
            Icon={InformationBubbleFlat}
            ctaConfigs={{
              text: "Télécharger",
              icon: "DownloadFlat",
              onClick: reDownloadKbis,
            }}
          />
        )}
      </DashboardContainer>

      <KbisPreviewerCover
        onDownloadCompleted={onDownloadKbisFromPreviewerComplete}
      />
    </>
  );
};

export default DashboardLayout;
