import { Tag } from "@yolaw/ui-kit-components";
import { UniversalBuilding } from "@yolaw/ui-kit-icons";
import { FC, memo } from "react";
import { styled } from "styled-components";

import { useApp, useLegalEntity, useSegment } from "hooks";
import { LegalEntity } from "types/legal-entities";
import LegalEntityBaseButton from "./LegalEntityBaseButton";

const Container = styled(LegalEntityBaseButton)`
  border-radius: ${(props) => props.theme.borderRadius.xs}px;
  &:hover {
    background-color: ${(props) => props.theme.colors.neutral.lightest};
  }
`;

type MenuButtonProps = {
  legalEntity: LegalEntity;
  onClickCallback?: () => void;
};

const MenuButton: FC<MenuButtonProps> = memo(
  ({ legalEntity: _le, onClickCallback }) => {
    const app = useApp();
    const segment = useSegment();
    const legalEntity = useLegalEntity(_le);

    const onMenuButtonClick = () => {
      segment.track("legalentity: selected");
      app.setCurrentLegalEntityId(legalEntity.id);
      onClickCallback?.();
    };

    return (
      <Container onClick={onMenuButtonClick}>
        <UniversalBuilding size="24" />
        <div className="le_selector-label_container">
          <span className="le_selector-le_name_label">{legalEntity.name}</span>
          {legalEntity.lsCompteProAccessibleSubscription && (
            <Tag color="blue" className="le_selector-le_tag">
              Compte Pro
            </Tag>
          )}
        </div>
      </Container>
    );
  }
);

export default MenuButton;
