import {
  Button,
  Checkbox,
  Column,
  Row,
  Text,
  TextField,
  Title,
} from "@yolaw/ui-kit-components";
import { useIsMobile, useSegment } from "hooks";
import { useEffect } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { Link, useSearchParams } from "react-router-dom";
import { AuthService, UserService } from "services";
import { BuiltRoutePath, SearchParams } from "services/router";
import styled, { css } from "styled-components";
import { EmailUtils } from "utils";
import { ERROR_MESSAGES, TOC_LINKS } from "utils/constants";
import { getNextParam } from "../auth-utils";
import { Divider, FormInnerContainer } from "../components/misc-components";
import NewPasswordFieldset from "../components/NewPasswordFieldset";

const Form = styled(FormInnerContainer).attrs({
  as: "form",
})``;

const CheckboxField = styled(Row)`
  align-items: flex-start;
  ${({ theme }) => css`
    && {
      column-gap: ${theme.spacing.xxxs}px;
      justify-content: flex-start;
    }

    > div {
      padding: 4px;
      padding-left: unset;
    }

    a {
      color: ${theme.colors.neutral.darker};
    }

    &.error {
      color: ${theme.colors.error.dark};
      div {
        border-color: inherit;
      }
      a {
        color: inherit;
      }
    }
  `};
`;

const StyledDivider = styled(Divider)`
  max-width: unset;
`;

const StyledButton = styled(Button)`
  ${({ theme }) => css`
    @container (min-width: ${theme.breakpoints.m}px) {
      align-self: center;
    }
  `}
`;

type FormInputs = {
  email: string;
  password: string;
  confirmPassword: string;
  emailOptIn: boolean;
  tocAgree: boolean;
};

export const SignUpPage = () => {
  const isMobile = useIsMobile();
  const segment = useSegment();
  const [searchParams] = useSearchParams();

  const formMethods = useForm<FormInputs>({
    mode: "onSubmit",
    criteriaMode: "all",
    values: {
      email: "",
      password: "",
      confirmPassword: "",
      emailOptIn: false,
      tocAgree: false,
    },
  });

  const { clearErrors, control, handleSubmit, formState, setError, watch } =
    formMethods;

  const { errors, isSubmitting } = formState;
  const email = watch("email");

  const onSubmit: SubmitHandler<FormInputs> = async (data: FormInputs) => {
    try {
      const { email, password, emailOptIn } = data;
      await UserService.signup({ email, password, ok_for_emails: emailOptIn });

      const user = await AuthService.getUserProfile();
      if (!user) {
        throw new Error(
          "Impossible d'obtenir les informations de l'utilisateur.",
          { cause: "signup_failed_to_get_user" }
        );
      }

      if (user.has_valid_email) {
        // BE can set some users `has_valid_email == true` by default to skip email validation
        const next = getNextParam();
        window.location.replace(next);
      } else {
        // New user needs to verify his email before accessing Userspace
        searchParams.set(SearchParams.Common.source, "signup");
        window.location.replace(
          BuiltRoutePath.EmailVerificationPage + `?${searchParams.toString()}`
        );
      }
    } catch (error: any) {
      setError("root", {
        type: "custom",
        message: error.message || ERROR_MESSAGES.DEFAULT,
      });
    }
  };

  useEffect(() => {
    if (errors.root) {
      clearErrors("root");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  useEffect(() => {
    segment.track("signup form: displayed");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Title
          text="Créez votre compte"
          type={isMobile ? "H1" : "H3"}
          color="secondary.main"
        />

        <Column>
          <Controller
            control={control}
            name="email"
            rules={{
              ...EmailUtils.emailFieldValidationRulesRHF,
              required: true,
            }}
            render={({ field: { ref, ...restOfField } }) => (
              <TextField
                type="email"
                label="Votre email"
                showLabel
                placeholder="Entrez votre email"
                hasError={!!errors.email}
                errorMessage={errors.email?.message}
                {...restOfField}
              />
            )}
          />

          <NewPasswordFieldset
            labels={{
              password: "Votre mot de passe",
              confirmPassword: "Confirmez votre mot de passe",
            }}
          />

          <CheckboxField>
            <Controller
              control={control}
              name="emailOptIn"
              render={({ field: { ref, value, ...restOfField } }) => (
                <Checkbox
                  {...restOfField}
                  id="emailOptIn"
                  checked={value}
                  value={JSON.stringify(value)}
                />
              )}
            />
            <label htmlFor="emailOptIn">
              <Text type="small">
                J'accepte que{" "}
                <strong>
                  Legalstart m'envoie des informations sur des promotions ou des
                  services fournis par Legalstart
                </strong>{" "}
                (promis, que des informations utiles, pas de spam&nbsp;!)
              </Text>
            </label>
          </CheckboxField>

          <CheckboxField className={!!errors.tocAgree && "error"}>
            <Controller
              control={control}
              name="tocAgree"
              rules={{
                required: true,
              }}
              render={({ field: { ref, value, ...restOfField } }) => (
                <Checkbox
                  {...restOfField}
                  id="tocAgree"
                  checked={value}
                  value={JSON.stringify(value)}
                />
              )}
            />
            <label htmlFor="tocAgree">
              <Text type="small">
                J’ai lu et j’accepte les{" "}
                <a
                  href={TOC_LINKS.CGU}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Conditions Générales
                </a>{" "}
                et la{" "}
                <a
                  href={TOC_LINKS.PRIVACY}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Charte de la vie privée
                </a>
              </Text>
            </label>
          </CheckboxField>
        </Column>

        {errors.root && (
          <Text
            type="small"
            text={errors.root.message}
            color="error.dark"
            fontWeightVariant="bold"
          />
        )}

        <StyledButton
          type="submit"
          disabled={isSubmitting}
          isLoading={isSubmitting}
        >
          Valider
        </StyledButton>
      </Form>

      <StyledDivider />

      <Text>
        Vous avez déjà un compte ?{" "}
        <Link to={BuiltRoutePath.LoginPage} replace>
          Se connecter
        </Link>
      </Text>
    </FormProvider>
  );
};
