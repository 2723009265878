import { displayToast, Spinner, Title } from "@yolaw/ui-kit-components";
import { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { AppContext } from "contexts";
import { useSegment } from "hooks";
import { PageContainer } from "pages/components/PageStyles";
import { InvoiceService } from "services";
import { WebInvoice, WebInvoiceSource } from "types/invoices";
import InvoicesPlaceholder from "./InvoicesPlaceholder";
import InvoicesSection from "./InvoicesSection";

const PageTitle = styled(Title)`
  margin-bottom: ${(props) => props.theme.spacing.m}px;

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    margin-bottom: ${(props) => props.theme.spacing.l}px;
  }
`;

const Sections = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${(props) => props.theme.spacing.l}px;
`;

const filterWebInvoicesBySource = (
  invoices: WebInvoice[],
  source: WebInvoiceSource
) => {
  return invoices.filter((invoice) => invoice.source === source);
};

export const InvoicesPage = () => {
  const segment = useSegment();
  const { state: appState, dispatch: appDispatch } = useContext(
    AppContext.Context
  );
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  const {
    invoices: { billing_documents, web_invoices },
  } = appState;

  const webFormalitiesInvoices = useMemo(
    () => filterWebInvoicesBySource(web_invoices, WebInvoiceSource.Formality),
    [web_invoices]
  );

  const webSubscriptionsInvoices = useMemo(
    () =>
      filterWebInvoicesBySource(web_invoices, WebInvoiceSource.Subscription),
    [web_invoices]
  );

  // Merge all the document types (transactional, voucher, credit note) invoices into 1 array
  const billingDocumentsInvoices = useMemo(
    () =>
      Object.values(billing_documents.documents || [])
        .flat()
        .filter(Boolean),
    [billing_documents.documents]
  );

  const hasDocumentInvoices = !!(
    webFormalitiesInvoices.length || billingDocumentsInvoices.length
  );

  const hasSubscriptionInvoices = !!(
    webSubscriptionsInvoices.length || billing_documents.subscriptions?.length
  );

  const hasInvoices = hasDocumentInvoices || hasSubscriptionInvoices;

  const _getInvoices = async () => {
    try {
      const invoicesData = await InvoiceService.getInvoices();

      appDispatch({
        type: AppContext.ActionType.SetInvoices,
        payload: invoicesData,
      });
    } catch (error) {
      displayToast({
        content: "Impossible d’obtenir les factures",
        type: "error",
      });
    } finally {
      setIsInitialLoading(false);
    }
  };

  useEffect(() => {
    _getInvoices();
    segment.track("invoice listing: displayed");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer>
      <PageTitle text="Mes Factures" type="h1" />

      {hasInvoices ? (
        <Sections>
          {hasDocumentInvoices ? (
            <InvoicesSection
              title="Démarches"
              invoices={{
                billingInvoices: billingDocumentsInvoices,
                webInvoices: webFormalitiesInvoices,
              }}
            />
          ) : null}
          {hasSubscriptionInvoices ? (
            <InvoicesSection
              title="Abonnements"
              invoices={{
                billingInvoices: billing_documents.subscriptions,
                webInvoices: webSubscriptionsInvoices,
              }}
            />
          ) : null}
        </Sections>
      ) : isInitialLoading ? (
        <Spinner />
      ) : (
        <InvoicesPlaceholder />
      )}
    </PageContainer>
  );
};
