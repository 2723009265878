import ApiService from "services/api";
import {
  Subscription,
  SubscriptionFamilySlug,
  SubscriptionStatus,
} from "types/subscriptions";

export type SubscriptionFilters = {
  statuses?: SubscriptionStatus[];
};

/** Find subscription by family slug.
 * Can filter by statuses.
 */
export const findSubscription = (
  subscriptions: Subscription[],
  subFamilySlug: SubscriptionFamilySlug,
  filters?: SubscriptionFilters
) => {
  const { statuses } = filters || {};
  return subscriptions.find(
    (sub) =>
      sub.family.slug === subFamilySlug &&
      (statuses?.includes(sub.status) ?? true)
  );
};

export const getSubscriptions = async () => {
  try {
    const response = await ApiService.subscriptions.getSubscriptions();
    return response?.data || [];
  } catch (error) {
    throw error;
  }
};
