
import AppBannerManager from "./AppBannerManager";
import LSCPAccess from "./LSCPAccess";

export const APP_BANNER_ELM_ID = "app_banner";

export const AppBanner = () => {
  if (AppBannerManager.hasDismissed()) {
    return null;
  }

  return (
    <div id={APP_BANNER_ELM_ID}>
      <LSCPAccess />
    </div>
  );
};

export default AppBanner;
