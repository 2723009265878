import { FC } from "react";
import styled, { css } from "styled-components";
import { Button, Text, TextButtonProps, Title } from "@yolaw/ui-kit-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => css`
    row-gap: ${theme.spacing.s}px;
    padding: ${theme.spacing.xs}px;
    background: ${theme.colors.common.white};
    border-radius: ${theme.borderRadius.s}px;
    box-shadow: ${theme.shadows.blue.small};
  `};
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${(props) => props.theme.spacing.xxxs}px;
  flex-grow: 1;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type FeatureCardProps = {
  icon: JSX.Element;
  title: string;
  description: string;
  primaryButtonOptions?: Pick<TextButtonProps, "onClick"> & {
    text: TextButtonProps["children"];
  };
  tertiaryButtonOptions?: Pick<TextButtonProps, "onClick" | "icon"> & {
    text: TextButtonProps["children"];
  };
  className?: string;
};

const StyledTertiaryButton = styled(Button).attrs({
  variant: "tertiary",
  size: "small",
})`
  svg {
    width: 20px;
    height: 20px;
  }
`;

const FeatureCard: FC<FeatureCardProps> = ({
  icon,
  title,
  description,
  primaryButtonOptions,
  tertiaryButtonOptions,
  className,
}) => {
  const isFooterDisplayed = primaryButtonOptions || tertiaryButtonOptions;

  return (
    <Container className={className}>
      <MainContent>
        {icon}
        <Title type="h4" text={title} />
        <Text type="body" text={description} />
      </MainContent>
      {isFooterDisplayed && (
        <Footer>
          {tertiaryButtonOptions && (
            <StyledTertiaryButton
              onClick={tertiaryButtonOptions.onClick}
              icon={tertiaryButtonOptions.icon}
            >
              {tertiaryButtonOptions.text}
            </StyledTertiaryButton>
          )}
          {primaryButtonOptions && (
            <Button size="small" onClick={primaryButtonOptions.onClick}>
              {primaryButtonOptions.text}
            </Button>
          )}
        </Footer>
      )}
    </Container>
  );
};

export default FeatureCard;
