import { Button, Column, Row, Text } from "@yolaw/ui-kit-components";
import { ChevronLeft } from "@yolaw/ui-kit-icons";
import { AppContext } from "contexts";
import { useUser } from "hooks";
import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { AdminService } from "services";
import styled, { css } from "styled-components";
import { CookiesUtils, EmailUtils } from "utils";

const ADMIN_TOOLBOX_ELEMENT_ID = "admin-toolbox";

const Container = styled(Column)`
  z-index: 6000;
  position: fixed;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  text-align: center;

  ${({ theme }) => css`
    border: 1px solid ${theme.colors.neutral.light};
    border-radius: ${theme.borderRadius.xs}px;

    background-color: ${theme.colors.primary.lighter};

    padding: ${theme.spacing.xs}px ${theme.spacing.s}px;

    &.viewing-as {
      background-color: ${theme.colors.error.light};
    }
  `}

  &:not(.expanded) {
    #toggler-icon {
      transform: rotate(180deg);
    }

    .hide-on-collapsed {
      display: none;
    }
  }

  transition: all 300ms ease-in-out;
  * {
    font-size: 0.8rem !important;
  }
`;

const QuickLinksContainer = styled(Row)`
  width: 100%;
`;

const SubmitButton = styled(Button)`
  min-width: 80px;
  span {
    gap: 0;
  }
`;

type FormInputs = {
  email: string;
};

const ViewAsController = () => {
  const { state: appState } = useContext(AppContext.Context);
  const { admin, user } = appState;

  const { formState, handleSubmit, register, setError } = useForm({
    defaultValues: {
      email: "",
    },
  });

  const { errors, isValid, isSubmitting } = formState;

  const isViewingAs = admin?.id !== user?.id;

  useEffect(() => {
    const toolboxElm = document.getElementById(ADMIN_TOOLBOX_ELEMENT_ID);
    toolboxElm?.classList.toggle("viewing-as", isViewingAs);
  }, [isViewingAs]);

  const onSubmit = async (data: FormInputs) => {
    const { error, success } = await AdminService.getViewAsToken(data.email);
    if (error) {
      setError("email", { message: error });
    }
    if (success) {
      // Store the current admin token in different cookies
      CookiesUtils.setAdminAccessToken(CookiesUtils.getAccessToken() || "");
      CookiesUtils.setAdminRefreshToken(CookiesUtils.getRefreshToken() || "");
      // Replace the current user tokens
      CookiesUtils.removeAuthTokens();
      CookiesUtils.setAccessToken(success.access);
      // Refresh the page
      window.location.reload();
    }
  };

  const disconnectViewAs = () => {
    // replace the current user tokens by admin tokens
    CookiesUtils.setAccessToken(CookiesUtils.getAdminAccessToken() || "");
    CookiesUtils.setRefreshToken(CookiesUtils.getAdminRefreshToken() || "");
    // cleanup the admin tokens
    CookiesUtils.removeAdminAuthTokens();
    // reload window
    window.location.reload();
  };

  return isViewingAs ? (
    <>
      <Text>Vous êtes connecté en tant que:</Text>
      <Text fontWeightVariant="bold">{user?.email}</Text>

      <Button size="small" onClick={disconnectViewAs}>
        Déconnecter View-As
      </Button>
    </>
  ) : (
    <>
      <Text>Se connecter en tant que (email):</Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Column>
          <Row>
            <input
              placeholder="email"
              {...register("email", {
                required: true,
                pattern: {
                  value: EmailUtils.EMAIL_REGEX,
                  message: "Cette adresse email est invalide.",
                },
              })}
            />
            <SubmitButton
              icon="ChevronRight"
              size="small"
              type="submit"
              variant="primary"
              disabled={!isValid || isSubmitting}
              isLoading={isSubmitting}
            >
              &nbsp;
            </SubmitButton>
          </Row>
          {errors.email && (
            <Text type="SMALL" color="error.dark" fontWeightVariant="bold">
              {errors.email.message}
            </Text>
          )}
        </Column>
      </form>
    </>
  );
};

const AdminToolbox = () => {
  const user = useUser();
  const { state: appState, dispatch: appDispatch } = useContext(
    AppContext.Context
  );
  const { admin, user: userProfile } = appState;

  const _checkAdmin = async () => {
    const adminProfile = user?.is_staff
      ? userProfile
      : await AdminService.getAdminProfile();

    appDispatch({
      type: AppContext.ActionType.SetAdminInfo,
      payload: adminProfile,
    });
  };

  useEffect(() => {
    _checkAdmin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!admin?.is_staff) return null;

  const toggleVisibility = () => {
    const toolboxElm = document.getElementById(ADMIN_TOOLBOX_ELEMENT_ID);
    toolboxElm?.classList.toggle("expanded");
  };

  const goToMezzanineAdminDashboard = () =>
    window.location.assign(process.env.REACT_APP_WEB_APP_DOMAIN + "/admin");

  const goToCoreApiAdminDashboard = () =>
    window.location.assign(
      process.env.REACT_APP_CORE_API_URL + "/django-admin"
    );

  return (
    <Container
      id={ADMIN_TOOLBOX_ELEMENT_ID}
      data-testid={ADMIN_TOOLBOX_ELEMENT_ID}
      className="expanded"
    >
      <QuickLinksContainer>
        <Row className={"hide-on-collapsed"}>
          <Button variant="tertiary" onClick={goToMezzanineAdminDashboard}>
            Mezzanine
          </Button>
          <Button variant="tertiary" onClick={goToCoreApiAdminDashboard}>
            CoreAPI
          </Button>
          <Button variant="tertiary" onClick={user.logout}>
            Déconnexion
          </Button>
        </Row>
        <Button variant="iconOnly" onClick={toggleVisibility}>
          <ChevronLeft id="toggler-icon" />
        </Button>
      </QuickLinksContainer>
      <Column className={"hide-on-collapsed"}>
        <ViewAsController />
      </Column>
    </Container>
  );
};

export default AdminToolbox;
