import { Button } from "@yolaw/ui-kit-components";
import dancingLockSrc from "assets/animation/dancingLock.json";
import Banner from "components/Banner";
import { ModalName } from "contexts/app";
import { useApp } from "hooks";
import lottie from "lottie-web";
import { useEffect } from "react";
import styled, { css } from "styled-components";
import useZenProject from "../hooks/useZenProject";

const Container = styled.div`
  ${({ theme }) => css`
    .banner-container {
      border: 2px solid ${theme.colors.accentuation.dark};
      box-shadow: ${theme.shadows.purple.medium};
    }
  `}
`;

const ZenUpsellBanner = () => {
  const app = useApp();
  const project = useZenProject();

  useEffect(() => {
    const animatedCrownElement = document.getElementById(
      "animated-thumbnail"
    ) as HTMLDivElement;

    const instance = lottie.loadAnimation({
      container: animatedCrownElement,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: dancingLockSrc,
    });

    return () => instance.destroy();
  }, []);

  const openPaymentModal = () => {
    app.openModal({
      name: ModalName.ZenSubscriptionPayment,
      openedBy: {
        context: project.currentDashboard,
        from: "banner",
      },
    });
  };

  return (
    <Container>
      <Banner
        title="Débloquez tout le potentiel de Zen"
        description="On vous guide et grâce aux dates précises à respecter, vous serez à jour dans vos obligations légales !"
        image={<div id="animated-thumbnail" />}
        button={
          <Button
            type="button"
            variant="primary"
            size="medium"
            onClick={openPaymentModal}
          >
            Découvrir
          </Button>
        }
      />
    </Container>
  );
};

export default ZenUpsellBanner;
