import { Text, Title } from '@yolaw/ui-kit-components';
import _get from 'lodash/get';
import React from 'react';
import styled, { css } from 'styled-components';
import CustomButton from '../../components/CustomButton';
import useTask from 'pages/zen/hooks/useTask';

const StyledTableRow = styled.tr`
  ${({ theme }) => css`
    /* border-bottom: 1px solid ${theme.colors.neutral.lighter}; */
  `}
`;

const StyledTableDataCell = styled.td`
  ${({ theme }) => css`
    padding: ${theme.spacing.xxs}px;
  `}
`;

const StyledTable = styled.table`
  border-spacing: 0px;
  width: 100%;
  text-align: left;

  ${({ theme }) => css`
    --column-border-radius: ${theme.borderRadius.m}px;

    /* Highlighted column elements */
    ${StyledTableDataCell}:nth-last-child(2) {
      background-color: ${theme.colors.tertiary.light};
    }

    /* Not-Highlighted column elements */
    ${StyledTableDataCell}:nth-of-type(even) {
      background-color: ${theme.colors.tertiary.lighter};
    }

    thead {
      ${StyledTableDataCell} {
        border-radius: var(--column-border-radius) var(--column-border-radius) 0
          0;
      }
    }

    tbody {
      ${StyledTableDataCell} {
        border-bottom: 1px solid ${theme.colors.neutral.lighter};
      }
    }

    tfoot {
      ${StyledTableDataCell} {
        border-radius: 0 0 var(--column-border-radius)
          var(--column-border-radius);
      }
      text-align: center;
    }
  `}
`;

type TableData = {
  columns: {
    id: string;
    display_name: string;
  }[];
  rows: {
    label: string;
    columns: { [columnId: string]: string };
  }[];
  buttons: { [columnId: string]: Task.ButtonConfigs };
};

type ComparisonTableProps = {
  dataSource: string;
};

const ComparisonTable = ({ dataSource }: ComparisonTableProps) => {
  const task = useTask();

  const tableData: TableData = _get(task, [dataSource]);

  if (!dataSource || !tableData)
    return <Text>{`[ComparisonTable] data source seems incorrect`}</Text>;

  return (
    <StyledTable>
      <thead>
        <StyledTableRow>
          <StyledTableDataCell></StyledTableDataCell>
          {tableData.columns.map((column) => (
            <StyledTableDataCell key={column.id}>
              <Title type="H4">{column.display_name}</Title>
            </StyledTableDataCell>
          ))}
        </StyledTableRow>
      </thead>

      <tbody>
        {tableData.rows.map((row) => (
          <StyledTableRow key={row.label}>
            <StyledTableDataCell>
              <Text type="SMALL" fontWeightVariant="bold">
                {row.label}
              </Text>
            </StyledTableDataCell>
            {tableData.columns.map((column) => (
              <StyledTableDataCell key={`${column.id}-${row.label}`}>
                {row.columns[column.id].split("\n").map((line) => (
                  <Text key={line} type="SMALL" color="neutral.dark">
                    {line}
                  </Text>
                ))}
              </StyledTableDataCell>
            ))}
          </StyledTableRow>
        ))}
      </tbody>

      <tfoot>
        <StyledTableRow>
          <StyledTableDataCell></StyledTableDataCell>
          {tableData.columns.map((column) => (
            <StyledTableDataCell key={`${column.id}`}>
              <CustomButton
                buttonConfig={{
                  ...tableData.buttons[column.id],
                  configs: {
                    ...tableData.buttons[column.id].configs,
                    size: "small",
                  },
                }}
              />
            </StyledTableDataCell>
          ))}
        </StyledTableRow>
      </tfoot>
    </StyledTable>
  );
};

export default ComparisonTable;
