import { useApp, useLegalEntity } from "hooks";
import { LoadingPage } from "pages";
import { Navigate, Outlet } from "react-router-dom";
import { BuiltRoutePath } from "services/router";

export const HomeOrFormalitiesPageRouter = () => {
  const app = useApp();
  const currentLegalEntity = useLegalEntity();

  const accessibleLSCompteProSub =
    currentLegalEntity.lsCompteProAccessibleSubscription;

  if (!app.subscriptions || !app.legalEntities) {
    // wait until both subscriptions & LEs are loaded
    return <LoadingPage />;
  }

  // no LSCP sub && trying to access BuiltRoutePath.FormalitiesPage
  // => force redirect to '{BuiltRoutePath.HomePage}' as Product team
  // wants to display the formalities there
  if (
    !accessibleLSCompteProSub &&
    window.location.pathname === BuiltRoutePath.FormalitiesPage
  ) {
    return <Navigate to={BuiltRoutePath.HomePage} replace />;
  }

  return <Outlet />;
};
