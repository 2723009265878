import { displayToast } from "@yolaw/ui-kit-components";
import ApiService from "services/api";
import { LegalEntity } from "types/legal-entities";

export const getDecisions = async () => {
  try {
    const response = await ApiService.eRecords.getDecisions();
    if (response) {
      return response.data;
    } else {
      displayToast({
        content: "Impossible d’obtenir des décisions !",
        type: "error",
      });
      return [];
    }
  } catch (error) {
    throw error;
  }
};

export const createDecision = async (creationData: DecisionCreationData) => {
  try {
    const response = await ApiService.eRecords.createDecision(creationData);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const finalizeDecision = async (decisionId: Decision['id']) => {
  try {
    const response = await ApiService.eRecords.finalizeDecision(decisionId);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const deleteDecision = async (decisionId: Decision["id"]) => {
  try {
    const response = await ApiService.eRecords.deleteDecision(decisionId);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const patchDecision = async (
  decisionId: Decision["id"],
  updatedProperties: Partial<Decision>
) => {
  try {
    const response = await ApiService.eRecords.patchDecision(
      decisionId,
      updatedProperties
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const createDocument = async (
  decisionId: Decision["id"],
  creationData: DecisionDocumentCreationData
) => {
  try {
    const response = await ApiService.eRecords.createDocument(
      decisionId,
      creationData
    );
    if (response?.data) return response.data;
    else throw new Error("Échec de la création d'un nouveau document !");
  } catch (error) {
    throw error;
  }
};

export const confirmDocumentFileUploaded = async (
  decisionId: Decision["id"],
  documentId: DecisionDocument["id"],
  data: DecisionDocumentUploadConfirmData
) => {
  try {
    const response = await ApiService.eRecords.confirmDocumentFileUploaded(
      decisionId,
      documentId,
      data
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const getDocument = async (
  decisionId: Decision["id"],
  documentId: DecisionDocument["id"]
) => {
  try {
    const response = await ApiService.eRecords.getDocument(
      decisionId,
      documentId
    );
    if (response?.data) return response.data;
    else throw new Error("Impossible d'obtenir le document !");
  } catch (error) {
    throw error;
  }
};

export const deleteDocument = async (
  decisionId: Decision["id"],
  documentId: DecisionDocument["id"]
) => {
  try {
    const response = await ApiService.eRecords.deleteDocument(
      decisionId,
      documentId
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const patchDocument = async (
  decisionId: Decision["id"],
  documentId: DecisionDocument["id"],
  updatedProperties: Partial<DecisionDocument>
) => {
  try {
    const response = await ApiService.eRecords.patchDocument(
      decisionId,
      documentId,
      updatedProperties
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const getRecordURL = async (legalEntityId: LegalEntity["id"]) => {
  try {
    const response = await ApiService.eRecords.getRecordURL(legalEntityId);
    if (response?.data) {
      return response?.data;
    }
    throw new Error("Impossible d'obtenir le registre !");
  } catch (error) {
    throw error;
  }
};
