import { Button, Label, Tag } from "@yolaw/ui-kit-components";
import { UniversalAdminFees2 } from "@yolaw/ui-kit-icons";
import { formatDate } from "date-fns";
import { FC } from "react";
import styled, { css } from "styled-components";

import { ModalName } from "contexts/app";
import { useApp, useLegalEntity, useSegment } from "hooks";
import useERecordRoutes from "pages/zen/pages/mandatory-records/hooks/useERecordRoutes";
import DecisionOptionsMenu from "./OptionsMenu";
import { DecisionCardContext } from "./context";

const DECISION_CARD_CQ_MEDIUM = "600px";

const ContainerQueryContainer = styled.div`
  container-type: inline-size;
`;

const ComponentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  ${({ theme }) => css`
    row-gap: ${theme.spacing.xs}px;
    padding: ${theme.spacing.xs}px;
    background: ${theme.colors.common.white};
    border-radius: ${theme.borderRadius.s}px;
    box-shadow: ${theme.shadows.blue.small};

    @container (min-width: ${DECISION_CARD_CQ_MEDIUM}) {
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: space-between;
      row-gap: ${theme.spacing.xxxs}px;
      column-gap: ${(props) => props.theme.spacing.xs}px;
    }
  `};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing.xxxs}px;
  flex: 1;
`;

const HeaderIconBox = styled.span`
  flex-shrink: 0;
`;

const Body = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${theme.spacing.xs}px;
    order: 1;
    flex-basis: 100%;

    @container (min-width: ${DECISION_CARD_CQ_MEDIUM}) {
      flex-direction: row;
      align-items: center;
      order: unset;
      flex-basis: unset;
    }
  `}
`;

const StyledTag = styled(Tag)`
  flex-shrink: 0;
`;

type DecisionCardProps = {
  decision: Decision;
};

const DecisionCard: FC<DecisionCardProps> = ({ decision }) => {
  const app = useApp();
  const currentLegalEntity = useLegalEntity();
  const segment = useSegment();
  const { goToDecisionViewPage } = useERecordRoutes();

  const {
    id: decisionId,
    title: decisionTitle,
    date: decisionDate,
    documents,
  } = decision;

  const createdAtTagText = `Date de la décision : ${formatDate(
    new Date(decisionDate),
    "dd/MM/yyyy"
  )}`;

  const onConsultButtonClick = () => {
    if (!currentLegalEntity.zenSub) {
      // This action need an active Zen sub
      app.openModal({
        name: ModalName.ZenSubscriptionPayment,
        openedBy: {
          context: "e-records decisions",
          from: "decision consult button",
        },
      });
      return;
    }

    segment.track("e-records decision: displayed", {
      legalentity_id: currentLegalEntity?.id,
    });

    goToDecisionViewPage(decisionId);
  };

  return (
    <DecisionCardContext.Provider value={{ decision }}>
      <ContainerQueryContainer>
        <ComponentContainer>
          <Header>
            <HeaderIconBox>
              <UniversalAdminFees2 size="32" />
            </HeaderIconBox>
            <Label
              text={decisionTitle}
              sizeVariant="LARGE"
              fontWeightVariant="normal"
            />
          </Header>
          <Body>
            {decision.finalized && <StyledTag color="green">Finalisé</StyledTag>}
            <StyledTag color="blue">{createdAtTagText}</StyledTag>
            <Button
              type="button"
              variant="secondary"
              size="small"
              onClick={onConsultButtonClick}
            >
              {documents.length ? "Consulter" : "Ajouter un document"}
            </Button>
          </Body>
          <DecisionOptionsMenu decision={decision} />
        </ComponentContainer>
      </ContainerQueryContainer>
    </DecisionCardContext.Provider>
  );
};

export default DecisionCard;
