import { Button, Container, Text, Title } from "@yolaw/ui-kit-components";
import useZenRoutes from "pages/zen/hooks/useZenRoutes";

const Succeed = () => {
  const { goToDashboard } = useZenRoutes();

  const handleCtaClick = () => {
    goToDashboard({ navigateOptions: { replace: true } });
  };

  return (
    <>
      <Title type="H2" color="secondary.main">
        🎉 Super, vous avez généré et téléchargé votre convention de mise à
        disposition !
      </Title>
      <Container size="s">
        <Text type="BODY" color="neutral.dark">
          💡 Imprimez votre convention et archivez-la dans vos documents. Elle
          vous sera notamment utile en cas de contrôle de l’administration.
        </Text>
      </Container>

      <Container size="xs">
        <Button onClick={handleCtaClick}>C'est fait !</Button>
      </Container>
    </>
  );
};

export default Succeed;
