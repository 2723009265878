import { Button } from "@yolaw/ui-kit-components";
import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { AppContext } from "contexts";
import { useSegment } from "hooks";
import WebinarBanner from "pages/components/Banners/WebinareBanner";
import { WEBINAR_URLS } from "pages/components/Banners/WebinareBanner/webinarURLs";
import { BuiltRoutePath } from "services/router";
import { LegalEntity } from "types/legal-entities";
import useDecisionsContext from "./hooks/useDecisionsContext";

const LOCAL_STORAGE_DISMISSED_KEY = "ls_erecords_webinar_banner_dismissed";

/** Object structure: `{ [path]: [dismissedAt] }` */
type DismissedEntity = {
  [path: string]: string;
};

type LocalStorageDismissedValue = Record<LegalEntity["id"], DismissedEntity>;

type ERecordsWebinarBannerProps = {
  context: string;
};

const ERecordsWebinarBanner = forwardRef<
  HTMLDivElement | null,
  ERecordsWebinarBannerProps
>(({ context }, ref) => {
  const segment = useSegment();
  const { decisionsByLegalEntity } = useDecisionsContext();
  const { state: appState } = useContext(AppContext.Context);
  const { currentLegalEntityId } = appState;
  const [shouldShowBanner, setShouldShowBanner] = useState(false);

  const currentPath = window.location.pathname;

  const localStorageDismissedInfo: LocalStorageDismissedValue = useMemo(
    () => JSON.parse(localStorage.getItem(LOCAL_STORAGE_DISMISSED_KEY) || "{}"),
    []
  );

  const dismissedInfoForCurrentPath: DismissedEntity | undefined =
    useMemo(() => {
      if (currentLegalEntityId) {
        return Object.entries(localStorageDismissedInfo).find(
          ([legalEntityId, info]) =>
            legalEntityId === String(currentLegalEntityId) && info[currentPath]
        )?.[1];
      }
      return undefined;
    }, [currentPath, currentLegalEntityId, localStorageDismissedInfo]);

  const checkShouldShowBannerByPage = useCallback(() => {
    if (dismissedInfoForCurrentPath) {
      const today = new Date();
      const dismissedAt = new Date(dismissedInfoForCurrentPath[currentPath]);

      switch (currentPath) {
        case BuiltRoutePath.MandatoryRecordsPage:
          const differenceInTime = today.getTime() - dismissedAt.getTime();
          const differenceInDays = Math.round(
            differenceInTime / (1000 * 3600 * 24)
          );

          // Show the banner if it was dismissed more than 2 weeks (14 days)
          // and there is no decision available at the moment
          setShouldShowBanner(
            differenceInDays > 14 && !decisionsByLegalEntity.length
          );
          break;
        default:
          setShouldShowBanner(true);
      }
    } else {
      setShouldShowBanner(true);
    }
  }, [currentPath, dismissedInfoForCurrentPath, decisionsByLegalEntity]);

  useEffect(() => {
    checkShouldShowBannerByPage();
  }, [checkShouldShowBannerByPage]);

  if (!shouldShowBanner || !currentLegalEntityId) return null;

  const dismissBanner = () => {
    segment.track("webinar banner: closed", {
      context,
      redirection_url: WEBINAR_URLS.ZEN,
    });

    // Set the current path with the current time
    localStorageDismissedInfo[currentLegalEntityId] = {
      ...localStorageDismissedInfo[currentLegalEntityId],
      [currentPath]: new Date().toISOString(),
    };

    // Store the information in localStorage
    localStorage.setItem(
      LOCAL_STORAGE_DISMISSED_KEY,
      JSON.stringify(localStorageDismissedInfo)
    );

    // Hide the banner
    setShouldShowBanner(false);
  };

  const openWebinarURL = () => {
    segment.track("webinar banner: clicked", {
      context,
      redirection_url: WEBINAR_URLS.ZEN,
    });
    window.open(WEBINAR_URLS.ZEN);
  };

  return (
    <div ref={ref}>
      <WebinarBanner
        testId="e-records-webinar-banner"
        title="Webinaire : Registre des décisions"
        subtitle="Nous répondons à toutes vos questions sur ce registre qui regroupe toutes les décisions de votre entreprise."
        CTA={
          <Button variant="secondary" size="small" onClick={openWebinarURL}>
            M'inscrire
          </Button>
        }
        onClose={dismissBanner}
      />
    </div>
  );
});

export default ERecordsWebinarBanner;
