import AppBannerManager from "layout/AppBanner/AppBannerManager";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

/** Do the actions when user lands on any of anon routes */
export const AnonymousResetRouter = () => {
  useEffect(() => {
    AppBannerManager.reset();
  }, []);

  return <Outlet />;
};
