import { Column, Row, Text, Title } from "@yolaw/ui-kit-components";
import SeeMoreToggleButton from "pages/components/SeeMoreToggleButton";
import ProgressBar from "pages/zen/components/ProgressBar";
import { useState } from "react";
import styled, { css } from "styled-components";
import { LIST_MAX_VISIBLE_ELEMENTS } from "utils/constants";
import AdministrativeTaskCard from "./AdministrativeTaskCard";

const SectionContainer = styled(Column).attrs({
  as: "section",
})`
  ${({ theme }) => css`
    background-color: ${theme.colors.tertiary.lighter};
    border-radius: ${theme.borderRadius.s}px;

    @container (max-width: ${theme.breakpoints.m}px) {
      && {
        padding: ${theme.spacing.xs}px;
        row-gap: ${theme.spacing.xs}px;
      }
    }

    @container (min-width: ${theme.breakpoints.m}px) {
      && {
        padding: ${theme.spacing.s}px;
        row-gap: ${theme.spacing.s}px;
      }
    }
  `}
`;

const SectionHeader = styled(Column)`
  && {
    row-gap: ${({ theme }) => theme.spacing.xxxxs}px;
  }
`;

const SectionBody = styled(Column)``;

const SectionProgressBar = styled(Row)`
  && {
    align-self: flex-start;
    align-items: center;
    column-gap: ${({ theme }) => theme.spacing.xxxxs}px;
  }
`;


const sortTasks = (tasks: TaskItem[]) => {
  return tasks.sort((taskA, taskB) => {
    // If both tasks are done, maintain default order
    if (taskA.is_done && taskB.is_done) {
      return tasks.indexOf(taskA) - tasks.indexOf(taskB);
    }
    // If only taskA is all done, move it to the end
    else if (taskA.is_done) {
      return 1;
    }
    // If taskB is done, keep it before taskA
    else if (taskB.is_done) {
      return -1;
    }
    // Else, maintain default order
    else {
      return tasks.indexOf(taskA) - tasks.indexOf(taskB);
    }
  });
};

type AdministrativeTaskSectionProps = {
  title: string;
  tasks: TaskItem[];
};

const AdministrativeTaskSection = ({
  title,
  tasks,
}: AdministrativeTaskSectionProps) => {
  const [isSectionExpanded, setIsSectionExpanded] = useState(false);

  if (!tasks.length) return null;

  const sortedTasks = sortTasks(tasks);

  const displayedTasks = isSectionExpanded
    ? sortedTasks
    : sortedTasks.slice(0, LIST_MAX_VISIBLE_ELEMENTS);

  const onSeeMoreToggleButtonClick = () =>
    setIsSectionExpanded(!isSectionExpanded);

  const completedTasksCount = sortedTasks.filter((task) => task.is_done).length;
  const completedTaskPercentage = Math.round(
    (completedTasksCount / sortedTasks.length) * 100
  );

  return (
    <SectionContainer>
      <SectionHeader>
        <Title type="H3">{title}</Title>
        <SectionProgressBar>
          {completedTaskPercentage ? (
            <>
              <Text type="body" fontWeightVariant="bold">
                {completedTaskPercentage}%
              </Text>
              <ProgressBar percentage={completedTaskPercentage} />
            </>
          ) : (
            <Text type="body" fontWeightVariant="bold">
              Réalisez votre première tâche !
            </Text>
          )}
        </SectionProgressBar>
      </SectionHeader>
      <SectionBody>
        {displayedTasks.map((task, index) => (
          <AdministrativeTaskCard key={index} task={task} />
        ))}
        {sortedTasks.length > LIST_MAX_VISIBLE_ELEMENTS && (
          <SeeMoreToggleButton
            isOpen={isSectionExpanded}
            onClick={onSeeMoreToggleButtonClick}
          >
            {isSectionExpanded ? "Voir moins" : "Voir plus"}
          </SeeMoreToggleButton>
        )}
      </SectionBody>
    </SectionContainer>
  );
};

export default AdministrativeTaskSection;
