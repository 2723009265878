import { Button, Column, displayToast, Text } from "@yolaw/ui-kit-components";
import { useSegment } from "hooks";
import useCountDown from "hooks/useCountDown";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AuthService } from "services";
import { BuiltRoutePath } from "services/router";
import styled, { css } from "styled-components";
import mailSvgSrc from "../assets/mail.svg";
import { getNextParam } from "../auth-utils";

const Divider = styled.div`
  height: 1px;
  width: 100%;
  max-width: 335px;
  background-color: ${({ theme }) => theme.colors.neutral.dark};
`;

const Container = styled(Column)`
  ${({ theme }) => css`
    align-items: center;
    text-align: center;
    color: ${theme.colors.neutral.darker};

    @container (max-width: ${theme.breakpoints.m}px) {
      && {
        row-gap: ${theme.spacing.m}px;
      }
    }

    @container (min-width: ${theme.breakpoints.m + 1}px) {
      && {
        row-gap: ${theme.spacing.s}px;
      }
    }
  `}
`;

const ActionsContainer = styled(Column)`
  ${({ theme }) => css`
    @container (max-width: ${theme.breakpoints.m}px) {
      && {
      }
    }

    @container (min-width: ${theme.breakpoints.m + 1}px) {
      && {
        align-items: inherit;
      }
    }
  `}
`;

const RESEND_COUNTDOWN_SECONDS = 15;

export const PasswordResetRequestPage = () => {
  const counter = useCountDown();
  const segment = useSegment();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { state: locationState } = useLocation();
  const emailAddress = locationState?.email;

  if (!emailAddress) {
    throw new Error("Non autorisé !", { cause: "missing_email" });
  }

  const sendEmail = useCallback(
    async ({ shouldDisplayNoti = true }) => {
      try {
        setIsSubmitting(true);

        const { redirect_url } = await AuthService.passwordResetRequest({
          email: emailAddress,
          next: getNextParam(),
        });

        if (redirect_url) {
          window.location.assign(redirect_url);
        } else {
          counter.start(RESEND_COUNTDOWN_SECONDS);

          if (shouldDisplayNoti) {
            displayToast({
              content: "Un nouvel email avec un lien de réinitialisation vient de vous être renvoyé.",
              type: "info",
            });
          }
        }
      } catch (error: any) {
        displayToast({
          content: error.message || "Something went wrong!",
          type: "error",
        });
      } finally {
        setIsSubmitting(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [emailAddress]
  );

  const resendEmail = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    sendEmail({ shouldDisplayNoti: true });
  };

  useEffect(() => {
    sendEmail({ shouldDisplayNoti: false });
    segment.track("password reset link sent page: displayed");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <img src={mailSvgSrc} width={124} alt="mail" />
      <Text fontWeightVariant="bold">
        Un email avec un lien de réinitialisation a été envoyé à {emailAddress}
      </Text>

      <Divider />

      <ActionsContainer>
        <Text>
          N'oubliez pas de vérifier vos spams. Si vous ne recevez pas l'email
          dans quelques minutes, cliquer sur :
        </Text>
        <Button
          variant="secondary"
          isLoading={isSubmitting}
          disabled={counter.isCounting || isSubmitting}
          onClick={resendEmail}
        >
          Renvoyer l'email
        </Button>
        {counter.isCounting && (
          <Text>
            Vous pourrez renvoyer l’email dans {counter.remainingSeconds}{" "}
            secondes.
          </Text>
        )}
        <Text>ou</Text>
        <Link to={BuiltRoutePath.LoginPage + window.location.search} replace>
          Me connecter
        </Link>
      </ActionsContainer>
    </Container>
  );
};
