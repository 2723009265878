import axios, { InternalAxiosRequestConfig } from "axios";
import { ApiService, AuthService } from "services";
import { CookiesUtils } from "utils";

const _computeAuthorizationHeaderValue = (accessToken?: string) =>
  accessToken ? `Bearer ${accessToken}` : undefined;

const setAuthorizationHeader = (
  requestConfig: InternalAxiosRequestConfig,
  accessToken?: string
) => {
  requestConfig.headers.Authorization =
    _computeAuthorizationHeaderValue(accessToken);
};

const coreApiInstance = axios.create({
  baseURL: process.env.REACT_APP_CORE_API_URL,
});

export const requestInterceptor = coreApiInstance.interceptors.request.use(
  (config) => {
    if (config.params?.disableAuthHeaderOverrideInInterceptor) {
      // There are some requests use their own token (ex: get admin profile)
      delete config.params.disableAuthHeaderOverrideInInterceptor;
    } else {
      // Always find & set auth header with latest tokens
      setAuthorizationHeader(config, CookiesUtils.getAccessToken());
    }

    return config;
  }
);

/** Store the retried URL list */
let retriedURLs: string[] = [];

coreApiInstance.interceptors.response.use(
  (response) => {
    // Assure the retried URL is removed from the list
    retriedURLs.filter((retriedURL) => retriedURL !== response.config.url);
    return response;
  },
  async (request) => {
    // Handle unauthorized requests
    if (request.response?.status === 401) {
      const errorCode = request.response.data["code"];
      const refreshToken = CookiesUtils.getRefreshToken();
      const requestURL = request.config.url;

      const shouldRefreshToken =
        // Don't retry a refresh request
        request.config?.url !== "/auth/token/refresh/" &&
        // Refresh token if the error code is `token_not_valid`
        errorCode === "token_not_valid" &&
        // must have a refresh token
        refreshToken &&
        // the request has never been retried
        !retriedURLs.some((retriedURL) => retriedURL === requestURL);

      if (shouldRefreshToken) {
        // Add the URL to the retried URL list
        retriedURLs.push(requestURL);

        // Try to request a new access token
        const refreshResponse =
          await ApiService.authentication.refreshAccessToken(refreshToken);

        if (refreshResponse?.data.access) {
          // Save the access token into cookies
          CookiesUtils.setAccessToken(refreshResponse.data.access);

          const authHeaderValue = _computeAuthorizationHeaderValue(
            refreshResponse.data.access
          );
          // Set the new access token to the original request headers
          request.config.headers["Authorization"] = authHeaderValue;
          // Set the new access token to the axios instance headers
          authHeaderValue &&
            (coreApiInstance.defaults.headers["Authorization"] =
              authHeaderValue);

          // Retry original request
          return coreApiInstance.request(request.config);
        }
      }
      AuthService.reconnect();
    }
  }
);

export default coreApiInstance;
