import { Accent, Button, Column, Text, Title } from "@yolaw/ui-kit-components";
import { useIsMobile, useLegalEntity, useSegment } from "hooks";
import { PageContainer } from "pages/components/PageStyles";
import styled, { css } from "styled-components";

import FormalitiesContext, { FormalityStatus } from "contexts/formalities";
import { FormalitiesPage } from "pages/formalities/FormalitiesPage";
import FormalitiesSection from "pages/formalities/FormalitiesSection";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BuiltRoutePath, RoutePath } from "services/router";
import lscpPreview from "./assets/lscp-preview.png";
import lscpPreview2x from "./assets/lscp-preview@2x.png";
import lscpPreview3x from "./assets/lscp-preview@3x.png";

const StyledPageContainer = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing.l}px;
`;

const HeaderContainer = styled(Column)`
  ${({ theme }) => css`
    @container (max-width: ${theme.breakpoints.m}px) {
      row-gap: ${theme.spacing.xxxs}px;
    }
  `}
`;

const SectionDivider = styled.hr`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.colors.neutral.lighter};
    border-bottom: unset;

    @container (max-width: ${theme.breakpoints.m}px) {
      display: none;
    }
  `}
`;

const StyledSection = styled(Column).attrs({ as: "section" })`
  ${({ theme }) => css`
    @container (max-width: ${theme.breakpoints.m}px) {
      row-gap: ${theme.spacing.l}px;
    }

    @container (min-width: ${theme.breakpoints.m + 1}px) {
      row-gap: ${theme.spacing.m}px;
    }
  `}
  > button {
    align-self: center;
  }
`;

const LSCPBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => css`
    background-color: ${theme.colors.primary.lighter};
    border-radius: ${theme.borderRadius.s}px;
    box-shadow: ${theme.shadows.green.small};
    padding: ${theme.spacing.s}px;

    @container (max-width: ${theme.breakpoints.m}px) {
      flex-direction: column;
      row-gap: ${theme.spacing.xs}px;
    }

    @container (min-width: ${theme.breakpoints.m + 1}px) {
      &.column-gap-l {
        column-gap: ${theme.spacing.l}px;
      }
      &.column-gap-xxxl {
        column-gap: ${theme.spacing.xxxl}px;
      }
    }
  `}

  #img-lscp-preview {
    flex-shrink: 0;
    width: 266px;
    height: 122px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: image-set(
      url(${lscpPreview}) 1x,
      url(${lscpPreview2x}) 2x,
      url(${lscpPreview3x}) 3x
    );
  }

  #lscp-info {
    align-items: center;
    text-align: center;
  }
`;

const HomePageContent = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const segment = useSegment();
  const currentLegalEntity = useLegalEntity();

  const {
    state: { groups },
  } = useContext(FormalitiesContext.Context);
  const inProgressFormalities =
    groups.byStatus[FormalityStatus.InProgress].formalities;

  // `x!` because we checked before rendering this component
  const accessibleLSCompteProSub =
    currentLegalEntity.lsCompteProAccessibleSubscription!;

  useEffect(() => {
    segment.track("userspace homepage: displayed", {
      le_id: currentLegalEntity.id,
      sub_ls_compte_pro_status: accessibleLSCompteProSub.status,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ssoURL = accessibleLSCompteProSub.buildSSORedirectionURL(
    RoutePath.LSCPProAccount
  );

  const goToFormalitiesPage = () => {
    navigate(BuiltRoutePath.FormalitiesPage);
  };

  const accessLSCP = () => {
    segment.track("ls compte pro cta: clicked", {
      le_id: currentLegalEntity.id,
      sub_ls_compte_pro_status: accessibleLSCompteProSub.status,
    });

    ssoURL && window.open(ssoURL);
  };

  return (
    <StyledPageContainer>
      <StyledSection>
        <HeaderContainer>
          <Title type={isMobile ? "H1" : "H2"}>
            {`${isMobile ? "" : "Mon "}Compte Pro Legalstart`}
          </Title>
          <Text>
            Payez, encaissez, virez de l’argent. Votre quotidien bancaire
            commence maintenant.
          </Text>
        </HeaderContainer>

        <LSCPBlock className={ssoURL ? "column-gap-xxxl" : "column-gap-l"}>
          <span id="img-lscp-preview" />
          <Column id="lscp-info">
            {ssoURL ? (
              // active LE
              <>
                <Title type="H4">Le Compte Pro tout-en-un</Title>
                <Button onClick={accessLSCP}>Accéder à mon compte</Button>
              </>
            ) : (
              // inactive LE
              <>
                <Title type="H3">
                  Votre <Accent color="information.dark">Compte Pro</Accent> est
                  bientôt prêt !
                </Title>
                <Title type="H4">
                  Il sera accessible dès que votre société sera immatriculée.
                </Title>
              </>
            )}
          </Column>
        </LSCPBlock>
      </StyledSection>

      <SectionDivider />

      <StyledSection>
        <HeaderContainer>
          <Title type={isMobile ? "H1" : "H2"}>Mes démarches en cours</Title>
          <Text>
            À vous de jouer ! Complétez vos questionnaires dans vos démarches
          </Text>
        </HeaderContainer>

        {/* In-progress Formalities */}
        <FormalitiesSection
          formalities={inProgressFormalities}
          ctaVariant="secondary"
        />

        {/* TODO: inactive LE */}
        <Button variant="secondary" size="small" onClick={goToFormalitiesPage}>
          Afficher mes autres démarches
        </Button>
      </StyledSection>
    </StyledPageContainer>
  );
};

export const HomePage = () => {
  const currentLegalEntity = useLegalEntity();

  const accessibleLSCompteProSub =
    currentLegalEntity.lsCompteProAccessibleSubscription;

  // no LSCP sub > render <FormalitiesPage />
  if (!accessibleLSCompteProSub) {
    return <FormalitiesPage />;
  }

  return <HomePageContent />;
};
