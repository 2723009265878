import LSUser from "types/user";

const LOCAL_STORAGE_KEY = "ls_should_verify_email_accounts";

/** Array of userID that needs to verify his email */
type DataStructure = Array<number>;

/** Add/Remove user from a local list of users that needs to verify the email.
 * @deprecated ⛔️ **WARNING! Stop using this once the email verification is mandatory
 * for all users.**
 */
export const updateShouldVerifyEmailIndicator = (user: LSUser.Info) => {
  const idArr: DataStructure = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_KEY) || "[]"
  );

  const foundIndex = idArr.findIndex((id) => id === user.id);

  if (user.has_valid_email) {
    // remove from list if it's there
    if (foundIndex !== -1) {
      idArr.splice(foundIndex, 1);
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(idArr));
    }
  } else {
    // add to list if it's not there
    if (foundIndex === -1) {
      idArr.push(user.id);
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(idArr));
    }
  }
};

/** Decide whether the given user should verify email or not */
export const shouldVerifyEmail = (user: LSUser.Info) => {
  /** For now, we only rely on the list stored in the local storage.
   * We don't check `user.has_valid_email` to not block users who already have access.
   * TODO: check `user.has_valid_email` once the email verification is mandatory for all users.**
   */
  const idArr: DataStructure = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_KEY) || "[]"
  );

  return idArr.includes(user.id);
};
