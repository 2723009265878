import { Button } from "@yolaw/ui-kit-components";
import React, { useEffect } from "react";

import useLegacySubscriptionPayment from "hooks/useLegacySubscriptionPayment";
import { PaymentMethod, PaymentOption } from "services/payment";
import { usePaymentModalContext } from "../../../hooks";
import ApplePayButton from "./ApplePay";
import GooglePayButton from "./GooglePay";

const PayButton = () => {
  const paymentModal = usePaymentModalContext();
  const {
    isReadyToPay,
    modalConfigs,
    modalRef,
    paymentMethod,
    paymentOption,
    selectedCard,
  } = paymentModal.state;

  const { subscriptionDisplayName } = modalConfigs;

  const lsPayment = useLegacySubscriptionPayment(
    paymentModal.selectedPlan,
    subscriptionDisplayName,
    modalRef
  );

  useEffect(() => {
    paymentModal.action.setPaymentError(lsPayment.paymentError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lsPayment.paymentError]);

  const handleCardPayment = () => {
    lsPayment.payWithCard(selectedCard?.payment_method_id);
  };

  const shouldDisableButton = !isReadyToPay || lsPayment.isProcessingPayment;

  if (
    paymentOption === PaymentOption.UseSavedCard ||
    paymentMethod === PaymentMethod.Card
  ) {
    return (
      <Button
        disabled={shouldDisableButton}
        isLoading={lsPayment.isProcessingPayment}
        onClick={handleCardPayment}
      >
        {`M’abonner à ${subscriptionDisplayName}`}
      </Button>
    );
  }

  if (paymentMethod === PaymentMethod.ApplePay) {
    return <ApplePayButton disabled={shouldDisableButton} />;
  }

  if (paymentMethod === PaymentMethod.GooglePay) {
    return <GooglePayButton disabled={shouldDisableButton} />;
  }

  return <></>;
};

export default React.memo(PayButton);
