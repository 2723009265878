import { Button } from "@yolaw/ui-kit-components";
import { useEffect, useState } from "react";

import { useLegalEntity, useSegment } from "hooks";
import WebinarBanner from "pages/components/Banners/WebinareBanner";
import { WEBINAR_URLS } from "pages/components/Banners/WebinareBanner/webinarURLs";
import { LegalEntity } from "types/legal-entities";
import { LegacySubscriptionSlug } from "types/legacy-subscriptions";

const LS_COMPTA_WEBINAR_BANNER_DISMISSED_KEY =
  "ls_compta_webinar_banner_dismissed";

const LSComptaWebinarBanner = () => {
  const segment = useSegment();
  const [hasDismissed, setHasDismissed] = useState(false);
  const currentLegalEntity = useLegalEntity();

  const lsComptaBasicSub = currentLegalEntity.findLegacySubscription(
    LegacySubscriptionSlug.LSComptaBasic
  );

  const dismissedArr: Array<LegalEntity["id"]> = JSON.parse(
    localStorage.getItem(LS_COMPTA_WEBINAR_BANNER_DISMISSED_KEY) || "[]"
  );

  useEffect(() => {
    setHasDismissed(dismissedArr.includes(Number(currentLegalEntity?.id)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (hasDismissed) return null;

  const dismissBanner = () => {
    // Store the current legal entity in localStorage
    localStorage.setItem(
      LS_COMPTA_WEBINAR_BANNER_DISMISSED_KEY,
      JSON.stringify(dismissedArr.concat(Number(currentLegalEntity?.id)))
    );
    // remove the banner
    setHasDismissed(true);
  };

  const openWebinarRegisterURL = () => {
    window.open(WEBINAR_URLS.LS_COMPTA_BASIC);

    segment.track("ls compta webinaire: clicked", {
      "sub comptastart": lsComptaBasicSub?.status || "none",
      cycle: lsComptaBasicSub?.plan.period_unit,
      "price (ht)": lsComptaBasicSub?.plan.price_et,
      type: lsComptaBasicSub?.plan.type_slug,
    });
  };

  return (
    <WebinarBanner
      title="Webinaire : apprenez à bien gérer votre logiciel de comptabilité"
      subtitle="On vous explique comment générer vos factures et faire vos notes de frais."
      CTA={
        <Button
          variant="secondary"
          size="small"
          onClick={openWebinarRegisterURL}
        >
          M'inscrire
        </Button>
      }
      onClose={dismissBanner}
    />
  );
};

export default LSComptaWebinarBanner;
