import { useApp } from "hooks";
import { NavigateOptions, generatePath, useNavigate } from "react-router-dom";

import { BuiltRoutePath, PathParams } from "services/router";

const ROUTE_BASE = BuiltRoutePath.MandatoryRecordsPage;

export const E_RECORDS_PATH_TEMPLATE = {
  DecisionView: {
    index: `${ROUTE_BASE}/:decisionId`,
  },
  RegistryView: `${ROUTE_BASE}/mon-registre`,
};

export const useERecordRoutes = () => {
  const navigate = useNavigate();
  const app = useApp();

  const basePathParams = {
    [PathParams.LegalEntityId]: app.currentLegalEntityId,
  };

  const goToDecisionsPage = () => {
    const decisionPagePath = generatePath(ROUTE_BASE, basePathParams);
    navigate(decisionPagePath);
  };

  const goToDecisionViewPage = (
    decisionId: number,
    options?: NavigateOptions
  ) => {
    const decisionViewPath = generatePath(
      E_RECORDS_PATH_TEMPLATE.DecisionView.index,
      {
        ...basePathParams,
        decisionId,
      }
    );
    navigate(decisionViewPath, options);
  };

  const goToMyRegistryPage = (recordFileURL: string) => {
    const registryViewPath = generatePath(
      E_RECORDS_PATH_TEMPLATE.RegistryView,
      basePathParams
    );
    navigate(registryViewPath, {
      state: {
        fileURL: recordFileURL,
      },
    });
  };

  return {
    goToDecisionsPage,
    goToDecisionViewPage,
    goToMyRegistryPage,
  };
};

export default useERecordRoutes;
