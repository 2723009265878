import { Button, Text } from "@yolaw/ui-kit-components";
import { CloseFlat } from "@yolaw/ui-kit-icons";
import { useApp, useLegalEntity } from "hooks";
import { useMemo } from "react";
import { LegalEntityService } from "services";
import { RoutePath } from "services/router";
import styled, { css } from "styled-components";
import AppBannerManager from "./AppBannerManager";

const BannerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #5360d5;

  > a {
    text-decoration-color: white;
  }

  > button {
    flex-shrink: 0;
  }

  ${({ theme }) => css`
    padding: ${theme.spacing.xxxs}px ${theme.spacing.xs}px;

    @media (max-width: ${theme.breakpoints.l}px) {
      column-gap: ${theme.spacing.xs}px;
    }
    @media (min-width: ${theme.breakpoints.l}px) {
      column-gap: ${theme.spacing.s}px;
    }
  `}
`;

export const LSCPAccess = () => {
  const app = useApp();
  const currentLegalEntity = useLegalEntity();

  const _LEToAccess = useMemo(() => {
    if (currentLegalEntity.lsCompteProAccessibleSubscription) {
      // Use the current LE if it has a LSCP linked
      return currentLegalEntity;
    } else {
      // find the latest LE that has a LSCP Linked
      if (app.legalEntities && app.subscriptions) {
        return LegalEntityService.findLatestLEHasLSCP(
          app.legalEntities,
          app.subscriptions
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLegalEntity.id]);

  const legalEntity = useLegalEntity(_LEToAccess);

  const ssoURL = useMemo(() => {
    return legalEntity.lsCompteProAccessibleSubscription?.buildSSORedirectionURL(
      RoutePath.LSCPProAccount
    );
  }, [legalEntity.lsCompteProAccessibleSubscription]);

  if (!ssoURL) {
    return null;
  }

  const handleDismiss = () => {
    AppBannerManager.dismiss();
  };

  return (
    <BannerContainer>
      <a href={ssoURL} target="_blank" rel="noreferrer">
        <Text fontWeightVariant="bold" color="common.white">
          Cliquez ici pour accéder au Compte Pro de la société{" "}
          {legalEntity.name}
        </Text>
      </a>
      <Button variant="iconOnly" onClick={handleDismiss}>
        <CloseFlat color="white" />
      </Button>
    </BannerContainer>
  );
};

export default LSCPAccess;
