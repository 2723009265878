import { Button, displayToast } from "@yolaw/ui-kit-components";
import { CancelButton, ModalForm } from "components/FormStyles";
import { formatDate } from "date-fns";
import { useContext, useState } from "react";

import Modal from "components/modal/Modal";
import { AppContext } from "contexts";
import { DecisionsService } from "services";
import useDecisionsContext from "../hooks/useDecisionsContext";
import useERecordRoutes from "../hooks/useERecordRoutes";
import DecisionModalFieldset, {
  generateDecisionTitle,
} from "./DecisionModalFieldset";

type DecisionCreationModalProps = {
  isOpen: boolean;
};

const DecisionCreationModal: React.FC<DecisionCreationModalProps> = ({
  isOpen,
}) => {
  const { state: appState } = useContext(AppContext.Context);
  const { addDecision, closeModal } = useDecisionsContext();
  const { goToDecisionViewPage } = useERecordRoutes();

  const [decisionDate, setDecisionDate] = useState(new Date());
  const [decisionTitle, setDecisionTitle] = useState(
    generateDecisionTitle(decisionDate)
  );
  const [isCreating, setIsCreating] = useState(false);

  const FooterContent = (
    <>
      <CancelButton
        formMethod="dialog"
        onClick={closeModal}
        icon="ChevronDownFlat"
      >
        Annuler
      </CancelButton>
      <Button
        type="submit"
        form="DecisionCreationForm"
        isLoading={isCreating}
        disabled={isCreating}
      >
        Créer une décision
      </Button>
    </>
  );

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsCreating(true);

    try {
      const newDecision = await DecisionsService.createDecision({
        legal_entity_id: Number(appState.currentLegalEntityId),
        title: decisionTitle,
        date: formatDate(decisionDate, "yyyy-MM-dd"),
      });

      if (newDecision) {
        // Update context
        addDecision(newDecision);
        // Then open the decision
        goToDecisionViewPage(newDecision.id);
      } else {
        throw new Error(
          "Impossible de créer une nouvelle décision. Veuillez réessayer ou contactez-nous !"
        );
      }
    } catch (error: any) {
      displayToast({
        content: error.message,
        type: "error",
      });
    } finally {
      setIsCreating(false);
      closeModal();
    }
  };

  return (
    <Modal
      onClose={closeModal}
      isOpen={isOpen}
      title="Créer une décision"
      FooterContent={FooterContent}
    >
      <ModalForm onSubmit={onFormSubmit} id="DecisionCreationForm">
        <DecisionModalFieldset
          decisionTitle={decisionTitle}
          setDecisionTitle={setDecisionTitle}
          decisionDate={decisionDate}
          setDecisionDate={setDecisionDate}
        />
      </ModalForm>
    </Modal>
  );
};

export default DecisionCreationModal;
