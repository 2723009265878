import coreApiInstance from "../coreApi";

export const login = (username: string, password: string) =>
  coreApiInstance.post<LoginResponse>(
    "/auth/login/",
    {
      username,
      password,
    },
    {
      validateStatus: (status) => status < 500,
    }
  );

export const refreshAccessToken = (refreshToken: string) =>
  coreApiInstance.post<RefreshTokenResponse>("/auth/token/refresh/", {
    refresh: refreshToken,
  });
