import { FC } from "react";
import styled, { css } from "styled-components";
import { Text, Title } from "@yolaw/ui-kit-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => css`
    row-gap: ${theme.spacing.xs}px;
    padding: ${theme.spacing.s}px;
    background: ${theme.colors.common.white};
    border-radius: ${theme.borderRadius.m}px;
    box-shadow: ${theme.shadows.blue.small};
  `};

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    flex-direction: row;
    align-items: center;
    column-gap: ${(props) => props.theme.spacing.xs}px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: ${(props) => props.theme.spacing.xs}px;
  flex-grow: 1;
`;

const HeaderImage = styled.figure`
  flex-basis: 62px;
  flex-shrink: 0;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    flex-basis: 100px;
  }
`;

const HeaderBody = styled.div``;

const MobileDescription = styled(Text)`
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    display: none;
  }
`;

const DesktopDescription = styled(Text)`
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    display: block;
  }
`;

type BannerProps = {
  image: JSX.Element;
  title: string | JSX.Element;
  description: string;
  button?: JSX.Element;
};

const Banner: FC<BannerProps> = ({ image, title, description, button }) => {
  return (
    <Container className="banner-container">
      <Header>
        <HeaderImage>{image}</HeaderImage>
        <HeaderBody>
          {typeof title === "string" ? <Title text={title} type="h3" /> : title}
          <DesktopDescription text={description} />
        </HeaderBody>
      </Header>
      <MobileDescription text={description} />
      {button}
    </Container>
  );
};

export default Banner;
